import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import moment from "moment";

const ContactForm = ({ button_name, downloadPdf, city, location, name }) => {
  const [loading, setLoading] = useState(false);
  const GOOGLE_SHEET_URL = "https://script.google.com/macros/s/AKfycbyYj59-3PDzCpjj4Ftt1n-peCKDqIX6ZBkSXDYlpmK_tqi1BBBN0oDCPQETs9bi0-II/exec";
  const url = window.location.href;
  const initialValues = {
    Name: "",
    Email: "",
    Mobile: "",
    city: city,
    location: location,
    url: url,
  };

  const phoneRegExp = /^[0-9]\d{9}$/;
  const validationSchema = Yup.object().shape({
    Name: Yup.string().trim().required("Name is required"),
    Email: Yup.string()
      .trim()
      .email("Invalid email")
      .required("Email is required"),
    Mobile: Yup.string()
      .matches(phoneRegExp, "Mobile is not valid")
      .required("Mobile is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("Name", values.Name);
      formData.append("Email", values.Email);
      formData.append("Mobile", values.Mobile);
      formData.append("city", city || "")
      formData.append("location", location || "")
      formData.append("url", url)
      formData.append("Date", moment().format("MMMM Do YYYY, h:mm:ss a"));
      setLoading(true);
      await axios({
        method: "post",
        url: GOOGLE_SHEET_URL,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          console.log("FORM_API_RES:::", response);
          setLoading(false);
          if (button_name === "Download Brochure") {
            downloadPdf();
          }
          toast.success("Thanks for contacting us.");
          resetForm();
        })
        .catch((error) => {
          setLoading(false);
          console.error("FORM_API_ERR:::", error);
          toast.error("Something went wrong.");
        });
    },
  });

  const { getFieldProps, handleSubmit, errors, touched } = formik;

  return (
    <>
      <ToastContainer style={{ zIndex: "99999" }} />
      <div className="form_heading">
        <h3 className="req_box">
          Yes, I'm Interested <br />
          {name && <span>in </span>}
          {name && <span className="contact_name">{name}</span>}
        </h3>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12 mb-4">
            <input
              type="text"
              placeholder="Name*"
              className="form-control modal_form_input"
              {...getFieldProps("Name")}
            />
            {errors.Name && touched.Name ? (
              <span className="error_validate">{errors.Name}</span>
            ) : null}
          </div>
          <div className="col-md-12 mb-4">
            <input
              type="email"
              placeholder="Email*"
              className="form-control modal_form_input"
              {...getFieldProps("Email")}
            />
            {errors.Email && touched.Email ? (
              <span className="error_validate">{errors.Email}</span>
            ) : null}
          </div>
          <div className="col-md-12 mb-4">
            <input
              type="text"
              placeholder="Phone*"
              className="form-control modal_form_input"
              {...getFieldProps("Mobile")}
            />
            {errors.Mobile && touched.Mobile ? (
              <span className="error_validate">{errors.Mobile}</span>
            ) : null}
          </div>
        </div>
        <button className="modal_form_btn" type="submit">
          {loading ? "Sending..." : "Submit"}
        </button>
      </form>
    </>
  );
};

export default ContactForm;


// import React, { useState } from "react";
// import axios from "axios"
// import { baseUrl } from "../../environment/apiconfig";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// function ContactForm({ button_name, downloadPdf, city, location, name }) {
//   const [nameError, setNameError] = useState("");
//   const [emailError, setEmailError] = useState("");
//   const [phoneError, setPhoneError] = useState("");
//   const [user, setUser] = useState({ name: "", email: "", phone: "" });
//   const [loading, setLoading] = useState(false);
//   const inputChangeHandler = (e) => {
//     let { name, value } = e.target;
//     setUser({ ...user, [name]: value });
//   };
//   const url = window.location.href;
//   const notify = () =>
//     toast.success("Thank You for submitting the query!", {
//       position: "bottom-right",
//       autoClose: 5000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "colored",
//     });

//   const notifyError = () =>
//     toast.error("Error Ocurred!", {
//       position: "bottom-right",
//       autoClose: 5000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "colored",
//     });

//   const [isSending, setIsSending] = useState(false);

//   const phonePattern = /^\d{10}$/;
//   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//   const validationName = () => {
//     if (user.name.trim() === "") {
//       setNameError("Name is required");
//     } else {
//       setNameError("");
//     }
//   };
//   const validationEmail = () => {
//     if (!emailPattern.test(user.email)) {
//       setEmailError("Invalid email format");
//     } else {
//       setEmailError("");
//     }
//   };

//   const validationPhone = () => {
//     if (!phonePattern.test(user.phone)) {
//       setPhoneError("Invalid phone number");
//     } else {
//       setPhoneError("");
//     }
//   };

//   const sendEmail = async (e) => {
//     e.preventDefault();
//     if (
//       user.name.trim() !== "" &&
//       emailPattern.test(user.email) &&
//       phonePattern.test(user.phone)
//     ) {
//       setUser({ name: "", email: "", phone: "" });
//       setIsSending(true);
//       validationName();
//       validationEmail();
//       validationPhone();
//       setLoading(true);
//       try {
//         await axios.post(
//           `${baseUrl}/sendmail`,
//           {
//             name: user.name,
//             email: user.email,
//             phone: user.phone,
//             city: city,
//             location: location,
//             PageLocation: url,
//           },
//           {
//             headers: {
//               "Content-Type": "application/json",
//             },
//           }
//         );
//         setLoading(false);
//         setIsSending(false);
//         handleSheet();
//         notify();
//         if (button_name === "Download Brochure") {
//           downloadPdf();
//         }
//       } catch (error) {
//         console.error(error);
//         notifyError();
//       }
//     } else {
//       validationName();
//       validationEmail();
//       validationPhone();
//     }
//   };

//   const dateTimeString = new Date().toLocaleString();
//   const [date, time] = dateTimeString.split(", ");
//   const GOOGLE_SHEET_URL = "https://script.google.com/macros/sAKfycbzqgW-q1ifiLByltfuBOWiaMQ23yFITVXdkBUFH3x9PMp5ad4HeonJcJgXMClf1fvKRTw/exec";

//   const handleSheet = async () => {

//     try {
//       const response = await fetch(
//         "https://script.google.com/macros/sAKfycbzqgW-q1ifiLByltfuBOWiaMQ23yFITVXdkBUFH3x9PMp5ad4HeonJcJgXMClf1fvKRTw/exec",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             name: user.name,
//             email: user.email,
//             phone: user.phone,
//             city: city,
//             location: location,
//             url: url,
//           }),
//         }
//       );
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       const data = await response.json();
//       console.log(data);
//     } catch (error) {
//       console.error('There has been a problem with your fetch operation:', error);
//     }
//   };

//   return (
//     <>
//       <ToastContainer style={{ zIndex: "99999" }} />
//       <div className="form_heading">
//         <h3 className="req_box">
//           Yes, I'm Interested <br />
//           {name && <span>in </span>}
//           {name && <span className="contact_name">{name}</span>}
//         </h3>
//       </div>
//       <form onSubmit={sendEmail}>
//         <div className="row">
//           <div className="col-md-12 mb-4">
//             <input
//               type="text"
//               className="form-control modal_form_input"
//               id="exampleInputtext"
//               aria-describedby="emailHelp"
//               placeholder="Name*"
//               value={user.name}
//               name="name"
//               onChange={inputChangeHandler}
//               onBlur={validationName}
//             />
//             {nameError && <p className="error_validate">{nameError}</p>}
//           </div>
//           <div className="col-md-12 mb-4">
//             <input
//               type="email"
//               placeholder="Email*"
//               className="form-control modal_form_input"
//               id="exampleInputEmail1"
//               aria-describedby="emailHelp"
//               onChange={inputChangeHandler}
//               onBlur={validationEmail}
//               name="email"
//               value={user.email}
//             />
//             {emailError && <p className="error_validate">{emailError}</p>}
//           </div>
//           <div className="col-md-12 mb-4">
//             <input
//               type="text"
//               placeholder="Phone*"
//               className="form-control modal_form_input"
//               id="exampleInputEmail1"
//               name="phone"
//               value={user.phone}
//               aria-describedby="emailHelp"
//               onChange={inputChangeHandler}
//               onBlur={validationPhone}
//             />
//             {phoneError && <p className="error_validate">{phoneError}</p>}
//           </div>
//         </div>
//         <button className="modal_form_btn" type="submit">
//           {isSending ? "Sending..." : "Submit"}
//         </button>
//       </form>
//     </>
//   );
// }

// export default ContactForm;
