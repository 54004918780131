import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_PROJECTS_BY_BUILDER } from "../service/BuildersService";

const useGetProjectsByBuilder = (builderName) => {
  const [projects, setProjects] = useState([]);
  const { loading, data } = useQuery(GET_ALL_PROJECTS_BY_BUILDER, {
    variables: {
      builderName: builderName,
    },
  });

  useEffect(() => {
    if (!loading && data) {
      setProjects(data?.projectsByBuilder);
    }
  }, [data, loading, builderName]);
  return [projects, loading];
};

export default useGetProjectsByBuilder;
