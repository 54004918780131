import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

function LeafletMap({ latitude, longitude, name, city, address }) {
  const position = [latitude, longitude];
  const [popupOpen, setPopupOpen] = useState(true);
  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  return (
    <div className="row map">
      <h3 className="mt30">
        <span className="primary_color text_black_mob">
          {name}{" "}
          {name &&
            !name.endsWith("gurgaon") &&
            !name.endsWith("Gurgaon") &&
            !name.endsWith("Gurugram") &&
            !name.endsWith("gurugram") &&
            city}{" "}
        </span>{" "}
        <br className="desk_hide" />
        on Google Map
      </h3>
      <p className="mt-2">{address}</p>
      <div className="map_box">
        <MapContainer center={position} zoom={15} scrollWheelZoom={false}>
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}{r}@2x.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            subdomains="abcd"
            maxZoom={19}
          />
          <Marker position={position}>
            <Popup onClose={handleClosePopup} open={popupOpen}>
              {name}
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  );
}

export default LeafletMap;
