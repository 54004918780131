import React, { useState, useEffect, useRef } from "react";
import "./City.css";
import locationImg from "../media/sample-image.png";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_MICROLOCATIONS } from "../../service/MicrolocationService";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ScrollBtn from "../scroll-btn/ScrollBtn";
import goal from "../media/goal.gif";
import Carousel from "react-bootstrap/Carousel";

function TopLocalities({ cityName, myCity }) {
  const city = cityName;
  const { loading, error, data } = useQuery(GET_MICROLOCATIONS, {
    variables: { city },
  });

  const [microlocations, setMicrolocations] = useState([]);
  useEffect(() => {
    if (data) {
      setMicrolocations(data.microlocations);
    }
  }, [data]);
  const cardRef = useRef(null);
  const containerRef = useRef(null);

  return (
    <div className="mt100 localities_main">
      <div className="container">
        <h2 className="heading underline">
          Top Localities in{" "}
          <span className="primary_color">
            {cityName}{" "}
            <img
              src={goal}
              alt="goal"
              className="featured_goal position-absolute"
            />
          </span>
        </h2>
        <div className="position-relative">
          {microlocations?.length > 4 && (
            <ScrollBtn cardRef={cardRef} scrollContainerRef={containerRef} />
          )}
          <div className="row top_localities flex_wrp carosal" ref={containerRef}>
            {microlocations?.slice(0, 8)?.map((microlocation, i) => {
              return (
                <div className="col-6 col-md-3 mt30" key={microlocation.id} ref={cardRef}>
                  <div className="localities_homecard">
                    <Carousel interval={null} controls={false} className="carousel-container">
                      <Carousel.Item key={microlocation.id}>
                        <Link
                          to={`/${myCity}/${microlocation.name
                            .split(" ")
                            .join("-")
                            .toLowerCase()}`}
                        >
                          <div className="localities_card h160">
                            <div className="img">
                              <LazyLoadImage
                                src={microlocation.image || locationImg}
                                alt="location name"
                                className="img-fluid"
                              />
                            </div>
                            <div className="localities_card_overlay city_localities">
                              <div className="overlay_text city_overlay_text mb-md-4 mb-2">
                                <h3>{microlocation.name}</h3>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopLocalities;