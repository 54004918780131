import React, { useState, useEffect } from "react";
import "./FilteredItems.css";
import { useQuery } from "@apollo/client";
import { GET_PROJECTS_BY_PLAN_TYPE } from "../../service/DataByPlanTypeService";
import { useParams } from "react-router-dom";
import ProjectSkeleton from "../loader/ProjectSkeleton";
import Filter from "../filter/Filter";
import HomeCard from "../card/HomeCard";
import CommonHeader from "../common-header/CommonHeader";
import FilterItemContactForm from "./FilterItemContactForm";

function FilteredItems() {
  const [isSearch, setIsSearch] = useState(false);
  
  const currentUrl = window.location.href;
  const parsedUrl = new URL(currentUrl);
  const path = parsedUrl.pathname;
  const pathParts = path.split("/");
  const desiredPart = pathParts[1];
  const city = desiredPart;
  const params = useParams();
  const { filteredValue } = params;
  const planType = filteredValue.split("-").join(" ");

  const { loading, error, data } = useQuery(GET_PROJECTS_BY_PLAN_TYPE, {
    variables: { city: city, planType: planType },
  });

  const [projects, setProjects] = useState(null);
  const [allProjects, setAllProjects] = useState(null);

  useEffect(() => {
    if (data) {
      setProjects(data.topProjectsByPlanAndCity);
      setAllProjects(data.topProjectsByPlanAndCity);
    }
  }, [data]);

  // const receiveDataFromChild = (datas) => {
  //   setProjects(datas);
  // };

  const filterData = (filteredData, isSearch) => {
    setProjects(filteredData);
    setIsSearch(isSearch);
  };
  // console.log(data, city, planType);

  return (
    <>
      <CommonHeader />
      {/* <div className="filtered_items_nav_main">
        <ProjectTypesNav
          city={desiredPart}
          showFilter={true}
          sendDataToParent={receiveDataFromChild}
          projectsData={data?.topProjectsByPlanAndCity}
          isMobile={true}
        />
      </div> */}

      <div className="container">
        <div className="filtered_items_main">
          <div className="d-flex justify-content-between">
            <h1 className="text-capitalize">Buy {planType} in Gurgaon</h1>
            {/* <MicrolocationTab projects={projects} filterData={filterData} /> */}
            <Filter projectsData={allProjects} filterData={filterData} />
          </div>
          <div className="row mt30">
            {loading && <ProjectSkeleton cards={8} />}
            {(isSearch ? projects?.length > 0 : allProjects?.length > 0)
              ? (isSearch ? projects : allProjects)?.map((project, i) => {
                  return (
                    <div className="col-sm-6 col-md-3 mb-4" key={i}>
                      <HomeCard
                        builder={project?.builder[0]?.name}
                        city={project?.location?.city[0]?.name}
                        projectName={project?.name}
                        startingPrice={project?.starting_price}
                        microlocationName={
                          project?.location?.micro_location[0]?.name
                        }
                        slug={project?.slug}
                        images={project?.images}
                        ratings={project?.ratings}
                      />
                    </div>
                  );
                })
              : !loading && <FilterItemContactForm />}
          </div>
        </div>
      </div>
    </>
  );
}

export default FilteredItems;
