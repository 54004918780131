import React, { useEffect, useState } from "react";
import "./HomeBanner.css";
import logo from "../../media/logo.png";
import mobBox from '../../media/mob-box.png';
import homeCity from '../../media/home-city.png'
import buildingImg from "../../media/sample-image.png";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Carousel from "react-bootstrap/Carousel";
import gurgaon from "../../media/gurgaon-mob.jpg";
import mumbai from "../../media/mumbai-mob.jpg";
import home_star from "../../media/home_star.svg";
import useGetProjectsByStatus from "../../../utils/useGetProjectsByStatus";
import { Link } from "react-router-dom";
import new_gif from "../../media/new_gif.gif";

function HomeBanner() {
  const [time, setTime] = useState(getCurrentTime());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(getCurrentTime());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function getCurrentTime() {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();

    const twelveHourFormat = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;

    return `${twelveHourFormat}:${minutes < 10 ? "0" : ""}${minutes}`;
  }

  const images = [
    {
      _id: "1",
      slug: "https://propularity.in/m3m-india/gurugram/m3m-crown-sector-111-gurgaon",
      img: "https://dwarkaexpressway-bucket.s3.ap-south-1.amazonaws.com/images/9392ad8edde19c592f9e19a1058a7c.jpg",
      name: "M3M Crown",
      location: "Dwarka expressway, gurugram",
      apartment: "3BHK & 4BHK Luxary Apartments",
    },
    {_id: "2",
      slug: "https://propularity.in/puri-group/gurugram/puri-diplomatic-residences",
      img: "https://dwarkaexpressway-bucket.s3.ap-south-1.amazonaws.com/images/10e70fdbafbcde41d742efd021bfc1.jpg",
      name: "Puri Diplomatic Residences",
      location: "Dwarka expressway, gurugram",
      apartment: "3BHK & 4BHK Luxary Apartments",
    },
    {
      _id: "3",
      slug: "https://propularity.in/ashiana-housing/gurugram/ashiana-amarah",
      img: "https://dwarkaexpressway-bucket.s3.ap-south-1.amazonaws.com/images/8df4292a9fd4320f049ba762a7056b.jpg",
      name: "Ashiana Amarah",
      location: "Dwarka expressway, gurugram",
      apartment: "3 BHK Luxary Apartments",
    },
    {
      _id: "4",
      slug: "https://propularity.in/aipl/gurugram/aipl-sector88-retail-market",
      img: "https://dwarkaexpressway-bucket.s3.ap-south-1.amazonaws.com/images/76ab3b32af7088dae7fa7f242fba80.jpg",
      name: "AIPL Joy District",
      location: "Dwarka expressway, gurugram",
      apartment: "2BHK & 3 BHK Luxary Apartments",
    },
    {_id: "5",
      slug: "https://propularity.in/m3m-india/gurugram/m3m-golf-hills-sector-79",
      img: "https://dwarkaexpressway-bucket.s3.ap-south-1.amazonaws.com/images/649152b941c80d868655ea1a885ca4.jpg",
      name: "M3M Golf Hills",
      location: "Dwarka expressway, gurugram",
      apartment: "2BHK, 3BHK & 4BHK Luxary Apartments",
    },
    {_id: "6",
      slug: "https://propularity.in/ss-group/gurugram/ss-cendana",
      img: "https://dwarkaexpressway-bucket.s3.ap-south-1.amazonaws.com/images/9d29f162c861dec918783c005a52db.jpg",
      name: "SS Cendana",
      location: "Dwarka expressway, gurugram",
      apartment: "3BHK Luxary Apartments",
    },
    {_id: "7",
      slug: "https://propularity.in/whiteland/gurugram/whiteland-the-aspen",
      img: "https://dwarkaexpressway-bucket.s3.ap-south-1.amazonaws.com/images/3f946a5345e49d863afdac7e488c8a.jpg",
      name: "Whiteland The Aspen",
      location: "NH8, gurugram",
      apartment: "3BHK & 4BHK Luxary Apartments",
    },
    {_id: "8",
      slug: "https://propularity.in/signature-global/gurugram/signature-deluxe-dxp",
      img: "https://dwarkaexpressway-bucket.s3.ap-south-1.amazonaws.com/images/6dacac675544e5de8ab7a9cfc0f560.jpg",
      name: "Signature Deluxe DXP 37D",
      location: "Dwarka expressway, gurugram",
      apartment: "2BHK & 3BHK Luxary Apartments",
    }
  ];

  const [projects, loading] = useGetProjectsByStatus("gurugram", "new launch");

  return (
    <>
      <div className="city_video_box city_video_box2 desk_hide">
        <div className="brand_logo desk_hide">
          <img src={logo} alt="propularity logo" />
          <h1>Where Property Comes To Life</h1>
          <div className="container">
            <div className="row mt-4">
              <div className="col-6">
                <Link to="/gurugram">
                  <div className="localities_card h160">
                    <div className="img">
                      <img
                        src={gurgaon}
                        alt="location name"
                        className="img-fluid"
                      />
                    </div>
                    <div className="localities_card_overlay city_localities">
                      <div className="overlay_text city_overlay_text mb-md-4 mb-2">
                        <h3 style={{ fontSize: "16px" }}>Gurugram</h3>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-6">
                <Link to="/mumbai">
                  <div className="localities_card h160">
                    <div className="img">
                      <img
                        src={mumbai}
                        alt="location name"
                        className="img-fluid"
                      />
                    </div>
                    <div className="localities_card_overlay city_localities">
                      <div className="overlay_text city_overlay_text mb-md-4 mb-2">
                        <h3 style={{ fontSize: "16px" }}>Mumbai</h3>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <h2 className="new_heading mt-4">New Launch Projects</h2>
            <div className="row_new">
              <Link
                to={images[0]?.slug}
              >
                <div className="circle_project position-relative">
                  <div className="gif">
                    <img src={new_gif} alt="new gif" />
                  </div>
                  <img
                    src={images[0]?.img}
                    alt="project image"
                  />
                  <p>{images[0]?.name}</p>
                </div>
              </Link>
              {images?.slice(1, 8)?.map((project) => (
                <Link
                  key={project?._id}
                  to={project?.slug}
                >
                  <div className="circle_project">
                    <img
                      src={project?.img}
                      alt="project image"
                    />
                    <p>{project?.name}</p>
                  </div>
                </Link>
              ))}
            </div>
            <a href="tel:9999063322" className="home_btns">
              Call us for best offers
            </a>
          </div>
        </div>
      </div>
      <div className="home_banner_main mob_hide">
        <div className="brand_logo">
          <img src={logo} alt="propularity logo" />
          <h1>Where Property Comes To Life</h1>
        </div>
        <div className="container my_container mob_hide">
          <div className="mob_box">
            <Carousel
              controls={false}
              interval={3000}
              className="carousel-container"
            >
              {images?.map((image, i) => {
                return (
                  <Carousel.Item key={i}>
                    <div className="img_cards">
                      <img
                        src={image.img}
                        alt={image.name}
                        className="img-fluid img_covers"
                      />
                    </div>
                    <div className="phone_text">
                      <p>{image.name}</p>
                      <p>{image.location}</p>
                      <p>{image.apartment}</p>
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
            <div className="time">{time}</div>
            <div className="phone_camera">
              <img
                src="https://propularity-bucket.s3.ap-south-1.amazonaws.com/image-1705648737693.png"
                alt="phone camera"
                className="phone_camera"
              />
            </div>
            <img
              src={mobBox}
              alt="mobile"
              className="img-fluid"
            />
          </div>
          <div className="city-div">
            <img className="img-fluid" src={homeCity} alt="city" />
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeBanner;
