import React, { useRef } from "react";
import HomeCard from "../card/HomeCard";
import useGetProjectsByBuilder from "../../utils/useGetProjectsByBuilder";
import ScrollBtn from "../scroll-btn/ScrollBtn";

const MorePropertiesBuilder = ({ builder }) => {
  const [projects, loading] = useGetProjectsByBuilder(builder);
  const cardRef = useRef(null);
  const containerRef = useRef(null);
  return (
    <div className="container">
      <h3 className="mt30">More Properties by {builder}</h3>
      <div className="position-relative">
        {projects?.length > 4 && (
          <ScrollBtn cardRef={cardRef} scrollContainerRef={containerRef} />
        )}
        <div className="row carosal" ref={containerRef}>
          {projects?.map((element) => (
            <div className="col-10 col-md-3" key={element?._id} ref={cardRef}>
              <HomeCard
                builder={element?.builder[0].name
                  .split(" ")
                  .join("-")
                  .toLowerCase()}
                city={element?.location?.city[0]?.name}
                projectName={element?.name}
                startingPrice={element?.starting_price}
                microlocationName={element?.location?.micro_location[0]?.name}
                slug={element?.slug}
                images={element?.images}
                ratings={element?.ratings}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MorePropertiesBuilder;
