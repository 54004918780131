import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "../media/logo.png";
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_MICROLOCATIONS } from "../../service/MicrolocationService";
import { FaBars } from "react-icons/fa";
import ContactFormModal from "../modal-form/ContactFormModal";
import Modal from "react-modal";

function Navbar() {
  // const cities = ["Gurugram", "Mumbai"];
  const { loading, error, data } = useQuery(GET_ALL_MICROLOCATIONS);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const [microlocations, setMicrolocations] = useState([]);
  useEffect(() => {
    if (data) {
      setMicrolocations(data.allmicrolocations);
    }
  }, [data]);

  let url = window.location.href;

  return (
    <>
      <div className="nav-main">
        <nav className="navbar navbar-expand-lg navbar_custom">
          <div className="container pl_0">
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="propularity-logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#main_nav"
              aria-controls="main_nav"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style={{ color: "#444", padding: "0" }}
            >
              <FaBars />
            </button>
            <div className="collapse navbar-collapse" id="main_nav">
              <ul
                className={
                  !url?.includes("gurugram")
                    ? "navbar-nav mx-auto custom_ul"
                    : "navbar-nav mx-auto custom_ul nav_ul"
                }
              >
                {!url?.includes("gurugram") && (
                  <li className="nav-item dropdown has-megamenu">
                    <img
                      src="https://propularity-bucket.s3.ap-south-1.amazonaws.com/image-1705650643885.png"
                      alt="mumbai icon"
                      className="nav_icon mob_hide"
                    />

                    <p
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      Mumbai
                    </p>
                    <div className="dropdown-menu megamenu" role="menu">
                      <div className="container">
                        <div className="megamenu-row">
                          <div className="mega_menu_items mega_menu_location">
                            <div className="row megamenu_locations">
                              {microlocations
                                ?.filter((microlocation) => {
                                  return microlocation.city.name === "Mumbai";
                                })
                                ?.map((filteredMicrolocation, i) => {
                                  return (
                                    <div
                                      className="col-md-4 location_name"
                                      key={i}
                                    >
                                      <Link
                                        to={`/mumbai/${filteredMicrolocation.name
                                          .split(" ")
                                          .join("-")
                                          .toLowerCase()}`}
                                      >
                                        {filteredMicrolocation.name}
                                      </Link>
                                    </div>
                                  );
                                })}
                              <Link to={`/mumbai`} className="m-0">
                                <button>View All</button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                )}
                {!url?.includes("gurugram") && (
                  <div className="vertical_line mob_hide"></div>
                )}
                <li className="nav-item dropdown has-megamenu">
                  <img
                    src="https://propularity-bucket.s3.ap-south-1.amazonaws.com/image-1705650682682.png"
                    alt="gurugram icon"
                    className="nav_icon mob_hide"
                  />

                  <p
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    Gurugram
                  </p>
                  <div className="dropdown-menu megamenu" role="menu">
                    <div className="container">
                      <div className="megamenu-row">
                        <div className="mega_menu_items mega_menu_location">
                          <div className="row megamenu_locations">
                            {microlocations
                              ?.filter((microlocation) => {
                                return microlocation.city.name === "Gurugram";
                              })
                              ?.slice(0, 8)
                              ?.map((filteredMicrolocation, i) => {
                                return (
                                  <div
                                    className="col-md-4 location_name"
                                    key={i}
                                  >
                                    <Link
                                      to={`/gurugram/${filteredMicrolocation.name
                                        .split(" ")
                                        .join("-")
                                        .toLowerCase()}`}
                                    >
                                      {filteredMicrolocation.name}
                                    </Link>
                                  </div>
                                );
                              })}
                            <Link to={`/gurugram`} className="m-0">
                              <button>View All</button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <ul className="navbar-nav">
                <li className="nav-item dropdown mob_hide">
                  <a
                    className="globalBtn callBack_btn_nav"
                    href="tel:9999063322"
                  >
                    Call: 9999063322
                  </a>
                </li>
                <li
                  className="nav-item dropdown mob_hide"
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                >
                  <a className="globalBtn callBack_btn_nav" onClick={openModal}>
                    Enquire Now
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <ContactFormModal closeModal={closeModal} />
      </Modal>
    </>
  );
}

export default Navbar;
