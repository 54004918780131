import React, { useState } from "react";

const AboutProject = ({ description, name, city }) => {
  const [showFullText, setShowFullText] = useState(false);
  const text = description;
  const isLongText = text && text.length > 500;

  return (
    <>
      {description !== "<p></p>\n" && description !== null && (
        <div className="row">
          <h3 className="mt30">
            About{" "}
            <span className="primary_color text_black_mob">
              {name}{" "}
              {name &&
                !name.endsWith("gurgaon") &&
                !name.endsWith("Gurgaon") &&
                !name.endsWith("Gurugram") &&
                !name.endsWith("gurugram") &&
                city}{" "}
            </span>
          </h3>
          <div className="project-description">
            {text && (
              <div
                className={`about_builder mt20 ${
                  isLongText && !showFullText ? "short-text" : ""
                }`}
              >
                {showFullText ? (
                  <div dangerouslySetInnerHTML={{ __html: text }}></div>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: text.substr(0, 500) + "...",
                    }}
                    className="text-justify"
                  ></div>
                )}
              </div>
            )}
            {isLongText ? (
              <p
                className="about_text_css"
                onClick={() => setShowFullText(!showFullText)}
              >
                {showFullText ? "See Less »" : "See More »"}
              </p>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default AboutProject;
