import React, { useState, useEffect } from "react";
import "./MicrolocationTab.css";
import { NavLink, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ALL_MICROLOCATIONS } from "../../service/MicrolocationService";

function MicrolocationTab() {
  const { data } = useQuery(GET_ALL_MICROLOCATIONS);
  const { filteredValue, micro } = useParams();

  const [microlocations, setMicrolocations] = useState([]);
  useEffect(() => {
    if (data) {
      setMicrolocations(
        data.allmicrolocations?.filter(
          (micro) => micro.city.name === "Gurugram"
        )
      );
    }
  }, [data]);

  return (
    <div className="location_tab">
      <ul className="visible_list">
        {microlocations?.map((microlocation, i) => {
          return (
            <li
              key={i}
              className={
                microlocation?.name?.toLowerCase() ===
                  micro?.split("-")?.join(" ") && "active_location"
              }
            >
              <NavLink
                to={`/gurugram/properties/${filteredValue}/${microlocation?.name
                  ?.split(" ")
                  ?.join("-")
                  ?.toLowerCase()}`}
              >
                {microlocation?.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default MicrolocationTab;
