import React from "react";

const Highlights = ({ highlights, name, city }) => {
  return (
    <>
      {highlights !== "<p></p>\n" && highlights !== null && (
        <>
          <div className="row">
            <h3 className="mt30">
              <span className="primary_color text_black_mob">
                {name}{" "}
                {name &&
                  !name.endsWith("gurgaon") &&
                  !name.endsWith("Gurgaon") &&
                  !name.endsWith("Gurugram") &&
                  !name.endsWith("gurugram") &&
                  city}{" "}
              </span>
              <br className="desk_hide" />
              Highlights
            </h3>
            <div
              dangerouslySetInnerHTML={{
                __html: highlights,
              }}
              className="project_highlights"
            ></div>
          </div>
          <hr className="divider_line" />
        </>
      )}
    </>
  );
};

export default Highlights;
