import React, { useEffect, useState } from "react";
import { NO_IMAGE } from "../../utils/constants";

const FloorPlan = ({ name, city, for_sale, for_rent, plans }) => {
  const [floorPlan, setFloorPlan] = useState("");

  useEffect(() => {
    if (plans) {
      setFloorPlan(plans[0]?.category?.name);
    }
  }, [plans]);

  const floorPlanChange = (e) => {
    const innerValue = e.target.innerText;
    const planType = innerValue.replace("floor plan", "");
    setFloorPlan(planType.trim());
  };

  const filteredPlan = plans?.filter(
    (plan) => plan?.category?.name?.toLowerCase() === floorPlan?.toLowerCase()
  );

  return (
    <>
      <div className="row">
        <h3 className="mt30">
          <span className="primary_color text_black_mob">
            {name}{" "}
            {name &&
              !name.endsWith("gurgaon") &&
              !name.endsWith("Gurgaon") &&
              !name.endsWith("Gurugram") &&
              !name.endsWith("gurugram") &&
              city}{" "}
          </span>{" "}
          <br className="desk_hide" />
          Floor Plans
        </h3>
        <div className="row floor_plan_scrollar mt-3">
          {plans?.map((plan, i) => (
            <button
              className="floor_plan_btn active"
              onClick={floorPlanChange}
              key={i}
            >
              {plan?.category?.name + " " + "floor plan"}
            </button>
          ))}
        </div>
        <div className="floor_configuration mt30">
          {filteredPlan &&
            filteredPlan.map((item) => {
              return item?.floor_plans?.map((myPlan) => (
                <div className="floor_plan_card lightbox" key={myPlan._id}>
                  <div className="floor_img">
                    <img
                      src={
                        myPlan?.image?.s3_link
                          ? myPlan.image?.s3_link
                          : NO_IMAGE
                      }
                      alt={
                        myPlan?.image?.s3_link
                          ? `${name} floor plan`
                          : "No floor plan"
                      }
                      className="img-fluid clickable-image"
                    />
                    {myPlan?.image?.s3_link && (
                      <>
                        <div className="view_floor_plan_img">
                          <button
                            type="button"
                            className="btn view_img_btn"
                            data-bs-toggle="modal"
                            data-bs-target={`#exampleModal${myPlan._id}`}
                          >
                            View Floor Plan
                          </button>
                        </div>
                        <div
                          className="modal fade"
                          id={`exampleModal${myPlan._id}`}
                          tabIndex="-1"
                          aria-labelledby={`exampleModalLabel${myPlan._id}`}
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id={`exampleModalLabel${myPlan._id}`}
                                >
                                  {`${name} floor plan`}
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <img
                                  src={myPlan.image?.s3_link}
                                  alt={`${name} floor plan`}
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="card_body">
                    <h5 className="card_title">
                      {myPlan?.name} {myPlan?.area}{" "}
                      {item?.size_sq ? item?.size_sq : "Sq.Ft"}
                    </h5>
                    <div className="row d-flex justify-content-between">
                      {for_sale && (
                        <div className="col-6">
                          <p>Sale Price</p>
                          <p>
                            {myPlan?.sale_price?.toString().toLowerCase() ===
                            "call for price" ? (
                              <a
                                href="tel:9999063322"
                                className="project_details_link"
                              >
                                Call For Price
                              </a>
                            ) : (
                              "Starting ₹ " + myPlan?.sale_price
                            )}
                          </p>
                        </div>
                      )}
                      {for_rent && (
                        <div className="col-6">
                          <p>Rent Price</p>
                          <p>
                            {myPlan?.rent_price?.toString().toLowerCase() ===
                              "call for price" || !myPlan?.rent_price ? (
                              <a
                                href="tel:9999063322"
                                className="project_details_link"
                              >
                                Call For Price
                              </a>
                            ) : (
                              "Starting ₹ " + myPlan?.sale_price
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ));
            })}
        </div>
      </div>
    </>
  );
};

export default FloorPlan;
