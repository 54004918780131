import React from "react";
import "./Builder.css";
import star from "../media/star-rating.png";
import { useParams, Link } from "react-router-dom";
import BuilderSlider from "../homepage/builders-slider/BuildersSlider";
import Card from "../card/Card";
import Carousel from "react-elastic-carousel";
import CommonHeader from "../common-header/CommonHeader";
import ProjectDetailSkeleton from "../loader/ProjectDetailSkeleton";
import ProjectContactForm from "../project/ProjectContactForm";
import ImageSection from "../project/ImageSection";
import useGetBuildersData from "../../utils/useGetBuildersData";
import useGetProjectsByBuilder from "../../utils/useGetProjectsByBuilder";

function Builder() {
  const { builder } = useParams();
  const slug = builder;
  const breakPoints = [
    { width: 1, itemsToShow: 1.4 },
    { width: 576, itemsToShow: 2.4 },
    { width: 768, itemsToShow: 2.4 },
    { width: 1200, itemsToShow: 3 },
    { width: 1500, itemsToShow: 4 },
  ];

  const [builderData, loading] = useGetBuildersData(slug);

  const builderName = builderData[0]?.name;

  const [builderProjects, loadingBuilder] =
    useGetProjectsByBuilder(builderName);

  return (
    <>
      {loading ? (
        <div className="mt100" style={{ minHeight: "100vh" }}>
          <ProjectDetailSkeleton />
        </div>
      ) : (
        <>
          <CommonHeader />
          <div className="container mt-5" style={{ minHeight: "100vh" }}>
            <div className="row mob_hide">
              <div className="col-12 col-md-6 m60">
                <h1 className="builder_h1">{builderData[0]?.name}</h1>
                {builderData[0]?.ratings && (
                  <img src={star} alt="star" className="star" />
                )}
                <p className="detail_p d-inline-block">
                  {builderData[0]?.ratings && builderData[0]?.ratings}{" "}
                  {builderData[0]?.estb_year &&
                    "Estd: " + builderData[0]?.estb_year}
                </p>
              </div>
              {builderData[0]?.starting_price && (
                <div className="col-6 m60 p-0 d-flex flex-column align-items-end mob_hide">
                  <p className="detail_p mob_hide">Starting Price</p>
                  <h1 className="mob_hide">
                    <span style={{ color: "#ff385c" }}>
                      ₹ {builderData[0]?.starting_price}
                    </span>{" "}
                    Onwards
                  </h1>
                </div>
              )}
            </div>
            <ImageSection
              images={builderData[0]?.images}
              project_size={builderData[0]?.configuration}
              project_status={builderData[0]?.commercial_num + "+"}
              project_type={builderData[0]?.cities?.length + "+"}
              configuration={builderData[0]?.residential_num + "+"}
              isBuilder={true}
              name={builderData[0]?.name}
            />
            {builderData[0]?.description !== "<p></p>\n" && (
              <hr className="devider_line desk_hide" />
            )}
            <div
              className={
                builderData[0]?.description !== "<p></p>\n" ? "row m60" : "row"
              }
            >
              <div className="col-12 col-md-9">
                <div className="main_section_detail">
                  {builderData[0]?.description !== "<p></p>\n" && (
                    <>
                      <h3>About {builderData[0]?.name}</h3>
                      <p className="about_builder">
                        {builderData[0]?.description}
                      </p>
                    </>
                  )}
                  {builderProjects.some(
                    (e) => e?.project_type.toLowerCase() === "residential"
                  ) && (
                    <>
                      <hr
                        className={
                          builderData[0]?.description !== "<p></p>\n"
                            ? "divider_line"
                            : "divider_line mt-0"
                        }
                      />
                      <div className="residential_carousel mt30">
                        <div className="res_heading">
                          <h3 className="d-inline-block">
                            Residential Projects of {builderData[0]?.name}
                          </h3>
                          <div>
                            <Link
                              to={`/builder/${builderData[0]?.name
                                ?.split(" ")
                                .join("-")
                                ?.toLowerCase()}/projects/residential`}
                              className="view_all_text"
                            >
                              View All
                            </Link>
                          </div>
                        </div>
                        <div className="row desk_hide city_row">
                          {builderProjects
                            ?.filter((project) => {
                              return (
                                project?.project_type?.toLowerCase() ===
                                "residential"
                              );
                            })
                            ?.slice(0, 8)
                            ?.map((project, i) => {
                              return (
                                <div className="col-10 mt30">
                                  <Card
                                    project={project}
                                    key={i}
                                    isbuilderPage={true}
                                  />
                                </div>
                              );
                            })}
                        </div>
                        <div className="my_carousel mob_hide">
                          <div className="mt30 carousel_container half_carousel">
                            <div className="carousel-wrapper">
                              <Carousel breakPoints={breakPoints}>
                                {builderProjects
                                  ?.filter((project) => {
                                    return (
                                      project?.project_type?.toLowerCase() ===
                                      "residential"
                                    );
                                  })
                                  ?.slice(0, 8)
                                  ?.map((project, i) => {
                                    return (
                                      <Card
                                        project={project}
                                        key={i}
                                        isbuilderPage={true}
                                      />
                                    );
                                  })}
                              </Carousel>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {builderProjects.some(
                    (e) => e?.project_type.toLowerCase() === "commercial"
                  ) && (
                    <>
                      <hr className="divider_line" />
                      <div className="residential_carousel mt30">
                        <div className="res_heading">
                          <h3 className="d-inline-block">
                            Commercial Projects of {builderData[0]?.name}
                          </h3>
                          <div>
                            <Link
                              className="view_all_text"
                              to={`/builder/${builderData[0]?.name
                                ?.split(" ")
                                .join("-")
                                ?.toLowerCase()}/projects/commercial`}
                            >
                              View All
                            </Link>
                          </div>
                        </div>
                        <div className="row city_row desk_hide">
                          {builderProjects
                            ?.filter((project) => {
                              return (
                                project?.project_type?.toLowerCase() ===
                                "commercial"
                              );
                            })
                            ?.slice(0, 8)
                            ?.map((project, i) => {
                              return (
                                <div className="col-10 mt30">
                                  <Card project={project} key={i} />
                                </div>
                              );
                            })}
                        </div>
                        <div className="my_carousel mob_hide">
                          <div className="mt30 carousel_container half_carousel">
                            <div className="carousel-wrapper">
                              <Carousel breakPoints={breakPoints}>
                                {builderProjects
                                  ?.filter((project) => {
                                    return (
                                      project?.project_type?.toLowerCase() ===
                                      "commercial"
                                    );
                                  })
                                  ?.slice(0, 8)
                                  ?.map((project, i) => {
                                    return <Card project={project} key={i} />;
                                  })}
                              </Carousel>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-3 mob_hide p-0">
                <div className="sticky_form">
                  <ProjectContactForm />
                </div>
              </div>
            </div>
            <hr className="divider_line" />
            <h3 className="mt30">Other Builders</h3>
            <BuilderSlider
              myClass={"other_builders"}
              name={builderData[0]?.name}
            />
          </div>
        </>
      )}
    </>
  );
}

export default Builder;
