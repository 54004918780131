import React, { lazy } from "react";
import TopProjects from "../../project/TopProjects";
import HomeBanner from "../home-banner/HomeBanner";
import TopLocalities from "../top-localities/TopLocalities";
import BuildersSlider from "../builders-slider/BuildersSlider";
import CommonHeader from "../../common-header/CommonHeader";
import HomeContact from "../home-contact/HomeContact";
import Faq from "../faq/Faq";
import TopFooter from "../../footer/TopFooter";
import FixedForm from "../../fixed-form/FixedForm";
import TopProperties from "../top-properties/TopProperties";

function Home() {
  return (
    <div>
      <CommonHeader />
      <HomeBanner />
      <TopProperties />
      <TopLocalities />
      <div className="mt100">
        <TopProjects city={"Gurugram"} isHome={true} />
      </div>
      <div className="mt100">
        <TopProjects city={"Mumbai"} isHome={true} />
      </div>
      <BuildersSlider myClass={"home_top_builders"} />
      <HomeContact />
      <Faq />
      <TopFooter />
      <FixedForm />
    </div>
  );
}

export default Home;
