import { gql } from "@apollo/client";

export const GET_PROJECT_DETAILS = gql`
  query projectsDetails($slug: String) {
    projectDetails(slug: $slug) {
      name
      builder {
        name
      }
      plans_type {
        name
      }
      starting_price
      brochure {
        s3_link
      }
      location {
        address
        latitude
        longitude
        city {
          name
        }
        micro_location {
          name
        }
      }
      images {
        name
        alt
        image {
          s3_link
        }
      }
      project_type
      configuration
      ratings
      project_size
      description
      project_status
      master_plan {
        s3_link
      }
      location_map {
        s3_link
      }
      short_descrip
      highlights
      advantages
      for_sale
      for_rent
      allAmenities {
        commercial {
          name
          icon
        }
        residential {
          name
          icon
        }
      }
      seo {
        title
        description
        robots
        keywords
        twitter {
          title
          description
        }
        open_graph {
          title
          description
        }
        script
      }
      plans {
        _id
        category {
          name
        }
        size
        size_sq
        floor_plans {
          _id
          area
          name
          sale_price
          image {
            s3_link
          }
        }
      }
    }
  }
`;

export const GET_ALL_BUILDERS = gql`
  {
    builders {
      _id
      name
      BuilderLogo
      slug
    }
  }
`;


