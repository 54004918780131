import React from "react";
import "./Project.css";
import { useParams } from "react-router-dom";
import RequestCallBtn from "../request-call-button/RequestCallBtn";
import LeafletMap from "./LeafletMap";
import ProjectDetailSkeleton from "../loader/ProjectDetailSkeleton";
import { Helmet } from "react-helmet-async";
import Price from "./Price";
import useGetProjectDetails from "../../utils/useGetProjectDetails";
import FloorPlan from "./FloorPlan";
import Highlights from "./Highlights";
import Amenities from "./Amenities";
import MasterPlan from "./MasterPlan";
import AboutProject from "./AboutProject";
import ProjectContactForm from "./ProjectContactForm";
import ApartmentForSale from "./ApartmentForSale";
import ApartmentForRent from "./ApartmentForRent";
import ImageSection from "./ImageSection";
import MorePropertiesBuilder from "./MorePropertiesBuilder";
import TopProjects from "./TopProjects";
import LocationAdvantages from "./LocationAdvantages";
import ProjectLocationImg from "./ProjectLocationImg";
import FixedForm from "../fixed-form/FixedForm";

function Project() {
  const { slug } = useParams();
  const [projectData, loading] = useGetProjectDetails(slug);

  const latitude = projectData[0]?.location?.latitude;
  const longitude = projectData[0]?.location?.longitude;

  const downloadPdf = async () => {
    try {
      const response = await fetch(projectData[0]?.brochure?.s3_link);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "downloaded-pdf.pdf";
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const currentUrl = window.location.href;

  return (
    <>
      <Helmet>
        <title>{projectData[0]?.seo?.title}</title>
        <meta name="description" content={projectData[0]?.seo?.description} />
        <meta name="keywords" content={projectData[0]?.seo?.keywords} />
        <meta
          property="og:title"
          content={projectData[0]?.seo?.open_graph?.title}
        />
        <meta
          property="og:description"
          content={projectData[0]?.seo?.open_graph?.description}
        />
        <meta
          name="twitter:title"
          content={projectData[0]?.seo?.twitter?.title}
        />
        <meta
          name="twitter:description"
          content={projectData[0]?.seo?.twitter?.description}
        />
        <link rel="canonical" href={currentUrl} />
        <meta name="robots" content={projectData[0]?.seo?.robots} />
        <script type="application/ld+json">
          {projectData[0]?.seo?.script}
        </script>
      </Helmet>
      {loading ? (
        <h1 className="mt100">
          <ProjectDetailSkeleton />
        </h1>
      ) : (
        <div className="container mt-5">
          <div className="row mob_hide">
            <div className="col-12 col-md-6 m60">
              <div className="property_head">
                <h1 className="builder_h1">{projectData[0]?.name}</h1>
                <i className="fa-solid fa-star"></i>
                <p className="detail_p d-inline-block">
                  <span className="me-2">{projectData[0]?.ratings}</span>{" "}
                  {projectData[0]?.location?.address}
                </p>
              </div>
            </div>
            <div className="col-6 m60 p-0 d-flex flex-column align-items-end mob_hide">
              <p className="detail_p mob_hide">Starting Price</p>
              <h1 className="mob_hide">
                <span style={{ color: "#ff385c" }}>
                  {projectData[0]?.starting_price.toString().toLowerCase() ===
                  "call for price" ? (
                    <a style={{ color: "#ff385c" }} href="tel:9999063322">
                      Call for price
                    </a>
                  ) : (
                    `₹ ${projectData[0]?.starting_price}`
                  )}
                </span>{" "}
                {projectData[0]?.starting_price.toString().toLowerCase() ===
                "call for price"
                  ? ""
                  : "Onwards"}
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-9 main-section">
              <ImageSection
                images={projectData[0]?.images}
                project_size={projectData[0]?.project_size}
                project_status={projectData[0]?.project_status}
                project_type={projectData[0]?.project_type}
                configuration={projectData[0]?.configuration}
                name={projectData[0]?.name}
                address={projectData[0]?.location?.address}
                ratings={projectData[0]?.ratings}
                starting_price={projectData[0]?.starting_price}
              />
              <div className="main_section_detail">
                <hr className="devider_line desk_hide" />
                <p className="about_builder mt-0 text-justify">
                  {projectData[0]?.short_descrip}
                </p>
                <Price
                  name={projectData[0]?.name}
                  city={projectData[0]?.location?.city[0]?.name}
                  plans={projectData[0]?.plans}
                />
                <hr className="divider_line mob_hide" />
                <FloorPlan
                  name={projectData[0]?.name}
                  city={projectData[0]?.location?.city[0]?.name}
                  for_rent={projectData[0]?.for_rent}
                  for_sale={projectData[0]?.for_sale}
                  plans={projectData[0]?.plans}
                />
                <hr className="divider_line" />
                <Highlights
                  name={projectData[0]?.name}
                  city={projectData[0]?.location?.city[0]?.name}
                  highlights={projectData[0]?.highlights}
                />
                <LocationAdvantages
                  name={projectData[0]?.name}
                  city={projectData[0]?.location?.city[0]?.name}
                  advantages={projectData[0]?.advantages}
                />
                <Amenities
                  name={projectData[0]?.name}
                  city={projectData[0]?.location?.city[0]?.name}
                  amenities={projectData[0]?.allAmenities}
                />
                {projectData?.length > 0 && latitude && longitude && (
                  <LeafletMap
                    name={projectData[0]?.name}
                    city={projectData[0]?.location?.city[0]?.name}
                    address={projectData[0]?.location?.address}
                    latitude={latitude}
                    longitude={longitude}
                  />
                )}
                <MasterPlan
                  name={projectData[0]?.name}
                  city={projectData[0]?.location?.city[0]?.name}
                  master_plan={projectData[0]?.master_plan}
                />
                <ProjectLocationImg
                  name={projectData[0]?.name}
                  city={projectData[0]?.location?.city[0]?.name}
                  locationImg={projectData[0]?.location_map}
                />
                <AboutProject
                  name={projectData[0]?.name}
                  city={projectData[0]?.location?.city[0]?.name}
                  micro_location={
                    projectData[0]?.location?.micro_location[0]?.name
                  }
                  description={projectData[0]?.description}
                />
                {projectData[0]?.brochure && (
                  <div className="brochure">
                    <RequestCallBtn
                      button_name={"Download Brochure"}
                      downloadPdf={downloadPdf}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-3 mt30 mob_hide p-0">
              <ProjectContactForm
                name={projectData[0]?.name}
                city={projectData[0]?.location?.city[0]?.name}
                micro_location={
                  projectData[0]?.location?.micro_location[0]?.name
                }
              />
            </div>
            <hr className="divider_line" />
            <ApartmentForSale
              name={projectData[0]?.name}
              city={projectData[0]?.location?.city[0]?.name}
              micro_location={projectData[0]?.location?.micro_location[0]?.name}
              plans_type={projectData[0]?.plans_type}
              images={projectData[0]?.images}
              starting_price={projectData[0]?.starting_price}
              plans={projectData[0]?.plans}
              for_sale={projectData[0]?.for_sale}
            />
            {projectData[0]?.for_sale && <hr className="divider_line" />}
            <ApartmentForRent
              name={projectData[0]?.name}
              city={projectData[0]?.location?.city[0]?.name}
              micro_location={projectData[0]?.location?.micro_location[0]?.name}
              plans_type={projectData[0]?.plans_type}
              images={projectData[0]?.images}
              starting_price={projectData[0]?.starting_price}
              plans={projectData[0]?.plans}
              for_rent={projectData[0]?.for_rent}
            />
            {projectData[0]?.for_rent && <hr className="divider_line" />}
            <MorePropertiesBuilder builder={projectData[0]?.builder[0]?.name} />
            <hr className="divider_line" />
            <TopProjects city={projectData[0]?.location?.city[0]?.name} />
          </div>
        </div>
      )}
      <FixedForm />
    </>
  );
}

export default Project;



// import React from "react";
// import "./Project.css";
// import { useParams } from "react-router-dom";
// import RequestCallBtn from "../request-call-button/RequestCallBtn";
// import LeafletMap from "./LeafletMap";
// import ProjectDetailSkeleton from "../loader/ProjectDetailSkeleton";
// import { Helmet } from "react-helmet-async";
// import Price from "./Price";
// import useGetProjectDetails from "../../utils/useGetProjectDetails";
// import FloorPlan from "./FloorPlan";
// import Highlights from "./Highlights";
// import Amenities from "./Amenities";
// import MasterPlan from "./MasterPlan";
// import AboutProject from "./AboutProject";
// import ProjectContactForm from "./ProjectContactForm";
// import ApartmentForSale from "./ApartmentForSale";
// import ApartmentForRent from "./ApartmentForRent";
// import BottomNav from "./BottomNav";
// import ImageSection from "./ImageSection";
// import MorePropertiesBuilder from "./MorePropertiesBuilder";
// import TopProjects from "./TopProjects";
// import LocationAdvantages from "./LocationAdvantages";
// import ProjectLocationImg from "./ProjectLocationImg";
// import FixedForm from "../fixed-form/FixedForm";

// function Project() {
//   const { slug } = useParams();
//   const [projectData, loading] = useGetProjectDetails(slug);

//   const latitude = projectData[0]?.location?.latitude;
//   const longitude = projectData[0]?.location?.longitude;

//   const downloadPdf = async () => {
//     try {
//       const response = await fetch(projectData[0]?.brochure?.s3_link);
//       const blob = await response.blob();
//       const url = window.URL.createObjectURL(blob);
//       const a = document.createElement("a");
//       a.href = url;
//       a.download = "downloaded-pdf.pdf";
//       a.click();
//       window.URL.revokeObjectURL(url);
//     } catch (error) {
//       console.error("Error downloading PDF:", error);
//     }
//   };

//   const currentUrl = window.location.href;

//   return (
//     <>
//       <Helmet>
//         <title>{projectData[0]?.seo?.title}</title>
//         <meta name="description" content={projectData[0]?.seo?.description} />
//         <meta name="keywords" content={projectData[0]?.seo?.keywords} />
//         <meta
//           property="og:title"
//           content={projectData[0]?.seo?.open_graph?.title}
//         />
//         <meta
//           property="og:description"
//           content={projectData[0]?.seo?.open_graph?.description}
//         />
//         <meta
//           name="twitter:title"
//           content={projectData[0]?.seo?.twitter?.title}
//         />
//         <meta
//           name="twitter:description"
//           content={projectData[0]?.seo?.twitter?.description}
//         />
//         <link rel="canonical" href={currentUrl} />
//         <meta name="robots" content={projectData[0]?.seo?.robots} />
//         <script type="application/ld+json">
//           {projectData[0]?.seo?.script}
//         </script>
//       </Helmet>
//       {loading ? (
//         <h1 className="mt100">
//           <ProjectDetailSkeleton />
//         </h1>
//       ) : (
//         <div className="container mt-5">
//           <div className="row mob_hide">
//             <div className="col-12 col-md-6 m60">
//               <div className="property_head">
//                 <h1 className="builder_h1">{projectData[0]?.name}</h1>
//                 <i className="fa-solid fa-star"></i>
//                 <p className="detail_p d-inline-block">
//                   <span className="me-2">{projectData[0]?.ratings}</span>{" "}
//                   {projectData[0]?.location?.address}
//                 </p>
//               </div>
//             </div>
//             <div className="col-6 m60 p-0 d-flex flex-column align-items-end mob_hide">
//               <p className="detail_p mob_hide">Starting Price</p>
//               <h1 className="mob_hide">
//                 <span style={{ color: "#ff385c" }}>
//                   {projectData[0]?.starting_price.toString().toLowerCase() ===
//                     "call for price" ? (
//                     <a style={{ color: "#ff385c" }} href="tel:9999063322">
//                       Call for price
//                     </a>
//                   ) : (
//                     `₹ ${projectData[0]?.starting_price}`
//                   )}
//                 </span>{" "}
//                 {projectData[0]?.starting_price.toString().toLowerCase() ===
//                   "call for price"
//                   ? ""
//                   : "Onwards"}
//               </h1>
//             </div>
//           </div>
//           <div className="row">
//             <div className="col-12 col-md-9">
//               <ImageSection
//                 images={projectData[0]?.images}
//                 project_size={projectData[0]?.project_size}
//                 project_status={projectData[0]?.project_status}
//                 project_type={projectData[0]?.project_type}
//                 configuration={projectData[0]?.configuration}
//                 name={projectData[0]?.name}
//                 address={projectData[0]?.location?.address}
//                 ratings={projectData[0]?.ratings}
//                 starting_price={projectData[0]?.starting_price}
//               />
//               <div className="main_section_detail">
//                 <hr className="devider_line desk_hide" />
//                 <p className="about_builder mt-0 text-justify">
//                   {projectData[0]?.short_descrip}
//                 </p>
//                 <Price
//                   name={projectData[0]?.name}
//                   city={projectData[0]?.location?.city[0]?.name}
//                   plans={projectData[0]?.plans}
//                 />
//                 <hr className="divider_line mob_hide" />
//                 <FloorPlan
//                   name={projectData[0]?.name}
//                   city={projectData[0]?.location?.city[0]?.name}
//                   for_rent={projectData[0]?.for_rent}
//                   for_sale={projectData[0]?.for_sale}
//                   plans={projectData[0]?.plans}
//                 />
//                 <hr className="divider_line" />
//                 <Highlights
//                   name={projectData[0]?.name}
//                   city={projectData[0]?.location?.city[0]?.name}
//                   highlights={projectData[0]?.highlights}
//                 />
//                 <LocationAdvantages
//                   name={projectData[0]?.name}
//                   city={projectData[0]?.location?.city[0]?.name}
//                   advantages={projectData[0]?.advantages}
//                 />
//                 <Amenities
//                   name={projectData[0]?.name}
//                   city={projectData[0]?.location?.city[0]?.name}
//                   amenities={projectData[0]?.allAmenities}
//                 />
//                 {projectData?.length > 0 && latitude && longitude && (
//                   <LeafletMap
//                     name={projectData[0]?.name}
//                     city={projectData[0]?.location?.city[0]?.name}
//                     address={projectData[0]?.location?.address}
//                     latitude={latitude}
//                     longitude={longitude}
//                   />
//                 )}
//                 <MasterPlan
//                   name={projectData[0]?.name}
//                   city={projectData[0]?.location?.city[0]?.name}
//                   master_plan={projectData[0]?.master_plan}
//                 />
//                 <ProjectLocationImg
//                   name={projectData[0]?.name}
//                   city={projectData[0]?.location?.city[0]?.name}
//                   locationImg={projectData[0]?.location_map}
//                 />
//                 <AboutProject
//                   name={projectData[0]?.name}
//                   city={projectData[0]?.location?.city[0]?.name}
//                   micro_location={
//                     projectData[0]?.location?.micro_location[0]?.name
//                   }
//                   description={projectData[0]?.description}
//                 />
//                 {projectData[0]?.brochure && (
//                   <div className="brochure">
//                     <RequestCallBtn
//                       button_name={"Download Brochure"}
//                       downloadPdf={downloadPdf}
//                     />
//                   </div>
//                 )}
//               </div>
//             </div>
//             <div className="col-lg-3 mt30 mob_hide p-0">
//               <ProjectContactForm
//                 name={projectData[0]?.name}
//                 city={projectData[0]?.location?.city[0]?.name}
//                 micro_location={
//                   projectData[0]?.location?.micro_location[0]?.name
//                 }
//               />
//             </div>
//             <hr className="divider_line" />
//             <ApartmentForSale
//               name={projectData[0]?.name}
//               city={projectData[0]?.location?.city[0]?.name}
//               micro_location={projectData[0]?.location?.micro_location[0]?.name}
//               plans_type={projectData[0]?.plans_type}
//               images={projectData[0]?.images}
//               starting_price={projectData[0]?.starting_price}
//               plans={projectData[0]?.plans}
//               for_sale={projectData[0]?.for_sale}
//             />
//             {projectData[0]?.for_sale && <hr className="divider_line" />}
//             <ApartmentForRent
//               name={projectData[0]?.name}
//               city={projectData[0]?.location?.city[0]?.name}
//               micro_location={projectData[0]?.location?.micro_location[0]?.name}
//               plans_type={projectData[0]?.plans_type}
//               images={projectData[0]?.images}
//               starting_price={projectData[0]?.starting_price}
//               plans={projectData[0]?.plans}
//               for_rent={projectData[0]?.for_rent}
//             />
//             {projectData[0]?.for_rent && <hr className="divider_line" />}
//             <MorePropertiesBuilder builder={projectData[0]?.builder[0]?.name} />
//             <hr className="divider_line" />
//             <TopProjects city={projectData[0]?.location?.city[0]?.name} />
//           </div>
//         </div>
//       )}
//       <FixedForm />
//     </>
//   );
// }

// export default Project;
