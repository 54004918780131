import React from "react";
import ContactForm from "../form/ContactForm";
import contactImage from "../media/offers_img.png";
import contactBg from "../media/detail-contact-bg.png";
import thumbs from "../media/thumbs.gif";

const ProjectContactForm = ({ city, micro_location, name }) => {
  return (
    <div className="sticky_form">
      <div
        className="form_box position-relative"
        style={{
          background: `url(${contactBg})`,
          backgroundSize: "cover",
        }}
      >
        <img src={thumbs} alt="thumbs" className="thumbs" />
        <ContactForm city={city} location={micro_location} name={name} />
      </div>
      <div className="contact_form_footer position-relative">
        <div className="img_box_form">
          <img src={contactImage} alt="contact image" className="img-fluid" />
        </div>
        <div className="text-center w-100">
          <a href="tel:9999063322" className="form_email">
            Call Now : 9999063322
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProjectContactForm;
