import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

function BuildersSlider({ builders, city }) {
  return (
    <div className="container builder_slider_container py-3">
      <h2 className="heading mb-md-3">
        Top Builders in{" "}
        <span className="primary_color">
          {city?.slice(0, 1)?.toUpperCase() + city?.slice(1)?.toLowerCase()}
        </span>
      </h2>
      <div className="builders_row">
        {builders?.map((builder, i) => {
          return (
            <Link
              key={i}
              to={`/builder/${builder?.slug}`}
              className="builder_logo_box"
            >
              <div>
                <LazyLoadImage
                  src={builder?.BuilderLogo}
                  alt={builder?.alt}
                  className="img-fluid"
                />
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default BuildersSlider;
