import React, { useRef } from "react";
import HomeCard from "../card/HomeCard";
import ScrollBtn from "../scroll-btn/ScrollBtn";
import useGetTopProjectsByCity from "../../utils/useGetTopProjectsByCity";
import star from "../media/heading_star.gif";
import whiteGoal from "../media/goal-animation-whitebg.gif";

const TopProjects = ({ city, isHome }) => {
  const [projects, loading] = useGetTopProjectsByCity(city);
  const cardRef = useRef(null);
  const containerRef = useRef(null);
  return (
    <div className="container">
      {!isHome && <h3 className="mt30">Top Properties in {city}</h3>}
      {isHome && (
        <>
          <h2 className="heading">
            Top Projects in{" "}
            <span className="primary_color position-relative">
              {city}{" "}
              {city === "Mumbai" ? (
                <img
                  src={whiteGoal}
                  alt="goal"
                  className="featured_goal position-absolute"
                />
              ) : (
                <img src={star} alt="star" />
              )}
            </span>
          </h2>
          <p className="heading_text mob_hide">
            Find Your Home In The City Of Your Choice
          </p>
        </>
      )}
      <div className="position-relative">
        {projects?.length > 4 && (
          <ScrollBtn cardRef={cardRef} scrollContainerRef={containerRef} />
        )}
        <div className="row carosal" ref={containerRef}>
          {projects?.slice(0, 8)?.map((element) => (
            <div
              className="col-10 col-md-3 mb-md-4"
              key={element?._id}
              ref={cardRef}
            >
              <HomeCard
                builder={element?.builder[0].name
                  .split(" ")
                  .join("-")
                  .toLowerCase()}
                city={element?.location?.city[0]?.name}
                projectName={element?.name}
                startingPrice={element?.starting_price}
                microlocationName={element?.location?.micro_location[0]?.name}
                slug={element?.slug}
                images={element?.images}
                ratings={element?.ratings}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopProjects;
