import React, { useState, useEffect } from "react";
import Select from "react-select";
import { GET_ALL_BUILDERS } from "../../service/ProjectDetailsservice";
import { useQuery } from "@apollo/client";
import { GET_ALL_MICROLOCATIONS } from "../../service/MicrolocationService";
import filterIcon from "../media/filter-icon.svg";
import { RxCross2 } from "react-icons/rx";
import Modal from "react-modal";

const Filter = ({ projectsData, filterData }) => {
  const [filteredItem, setFilteredItem] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedBuilder, setSelectedBuilder] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [curPage, setCurPage] = useState(1);
  const [isSearch, setIsSearch] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const cities = ["Gurugram", "Mumbai"];
  const { loading, error, data } = useQuery(GET_ALL_MICROLOCATIONS);

  const [microlocations, setMicrolocations] = useState([]);
  useEffect(() => {
    if (data) {
      setMicrolocations(data.allmicrolocations);
    }
  }, [data]);

  const {
    loading: isLoading,
    error: isError,
    data: builderData,
  } = useQuery(GET_ALL_BUILDERS);

  function convertPriceToNumeric(priceStr) {
    const regexCr = /([\d.]+)\s*Cr/;
    const regexLacs = /([\d.]+)\s*Lacs?/i;
    const matchCr = priceStr.match(regexCr);
    if (matchCr) {
      const value = parseFloat(matchCr[1]);
      return value * 10000000;
    }
    const matchLacs = priceStr.match(regexLacs);
    if (matchLacs) {
      const value = parseFloat(matchLacs[1]);
      return value * 100000;
    }
    return 0;
  }

  const applyFilters = () => {
    let filteredData = filteredItem;

    if (selectedBuilder) {
      filteredData = filteredData?.filter(
        (project) => project?.builder[0]?.name === selectedBuilder.label
      );
      setIsSearch(true);
    }

    if (selectedLocation) {
      filteredData = filteredData?.filter(
        (project) =>
          project?.location?.micro_location[0]?.name === selectedLocation.label
      );
      setIsSearch(true);
    }

    if (selectedStatus) {
      filteredData = filteredData?.filter(
        (project) => project?.project_status === selectedStatus.label
      );
      setIsSearch(true);
    }

    if (selectedPrice) {
      const [minPrice, maxPrice] = selectedPrice.value.split(" - ");
      const minPriceVal = convertPriceToNumeric(minPrice);
      const maxPriceVal = convertPriceToNumeric(maxPrice);
      filteredData = filteredData?.filter((project) => {
        const projectPrice = convertPriceToNumeric(project?.starting_price);
        if (minPriceVal === 100000000) {
          return parseFloat(projectPrice) >= parseFloat(minPriceVal);
        }
        return (
          parseFloat(projectPrice) >= parseFloat(minPriceVal) &&
          parseFloat(projectPrice) <= parseFloat(maxPriceVal)
        );
      });
      setIsSearch(true);
    }

    filterData(filteredData, isSearch);
    setCurPage(1);
  };

  useEffect(() => {
    setFilteredItem(projectsData);
  }, [projectsData]);

  useEffect(() => {
    applyFilters();
  }, [selectedBuilder, selectedStatus, selectedPrice, selectedLocation]);

  const onChangeOptionHandler = (selectedOption, dropdownIdentifier) => {
    switch (dropdownIdentifier) {
      case "status":
        setSelectedStatus(selectedOption);
        setIsSearch(true);
        break;
      case "price":
        setSelectedPrice(selectedOption);
        setIsSearch(true);
        break;
      case "builder":
        setSelectedBuilder(selectedOption);
        setIsSearch(true);
        break;
      case "location":
        setSelectedLocation(selectedOption);
        setIsSearch(true);
        break;
      default:
        break;
    }
  };
  const statusOptions = [
    { value: "Ready To Move", label: "Ready To Move" },
    { value: "Under Construction", label: "Under Construction" },
    { value: "New Launch", label: "New Launch" },
  ];

  const priceOptions = [
    { value: "2cr", label: "Upto 2Cr" },
    { value: "2Cr - 4Cr", label: "2Cr - 4Cr" },
    { value: "4Cr - 6Cr", label: "4Cr - 6Cr" },
    { value: "6Cr - 8Cr", label: "6Cr - 8Cr" },
    { value: "8Cr - 10Cr", label: "8Cr - 10Cr" },
    { value: "10Cr", label: "10Cr+" },
  ];

  const builderOptions = builderData?.builders?.map((builder) => ({
    value: builder._id,
    label: builder.name,
  }));

  const microlocationOptions = microlocations
    ?.filter((microlocation) => microlocation.city.name === "Gurugram")
    ?.slice(0, 8)
    ?.map((filteredMicrolocation) => ({
      value: filteredMicrolocation.name,
      label: filteredMicrolocation.name,
    }));

  const resetFilterHandler = () => {
    setSelectedBuilder(null);
    setSelectedLocation(null);
    setSelectedStatus(null);
    setSelectedPrice(null);
    setIsSearch(false);
    setFilteredItem(projectsData);
  };

  const closeFilters = () => {
    applyFilters();
    closeModal();
  };

  const pageUrl = window.location.href;

  useEffect(() => {
    return () => resetFilterHandler();
  }, [pageUrl]);

  return (
    <div className="row justify-content-md-end filter_row">
      <div className="col-2 desk_hide filter_box">
        <button onClick={openModal}>
          <img src={filterIcon} alt="filter icon" />
        </button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div
          className="modal_filter_main"
          style={{
            height: "235px",
            borderRadius: "8px",
            marginTop: "50%",
            padding: "10px",
          }}
        >
          <div className="cross_icon">
            <button>
              <RxCross2 className="close_icon" onClick={closeModal} />
            </button>
          </div>
          <div className="row mt-4">
            <div className="col-6 mb-4">
              <Select
                value={selectedBuilder}
                onChange={(selectedOption) =>
                  onChangeOptionHandler(selectedOption, "builder")
                }
                isSearchable={false}
                options={builderOptions}
                placeholder={"Builder"}
                className="select_builder"
              />
            </div>
            <div className="col-6 mb-4">
              <Select
                value={selectedStatus}
                onChange={(selectedOption) =>
                  onChangeOptionHandler(selectedOption, "status")
                }
                isSearchable={false}
                options={statusOptions}
                placeholder={"Status"}
                className="select_builder"
              />
            </div>
            <div className="col-6 mb-4">
              <Select
                value={selectedPrice}
                onChange={(selectedOption) =>
                  onChangeOptionHandler(selectedOption, "price")
                }
                options={priceOptions}
                placeholder={"Price"}
                className="select_builder"
                isSearchable={false}
              />
            </div>
            <div className="col-6 mb-4">
              <Select
                value={selectedLocation}
                onChange={(selectedOption) =>
                  onChangeOptionHandler(selectedOption, "location")
                }
                isSearchable={false}
                options={microlocationOptions}
                placeholder={"Location"}
                className="select_builder"
                menuPortalTarget={document.body}
              />
            </div>
            <div className="filter_btn_grp">
              <div>
                <button
                  className="btn globalBtn clear_filter"
                  onClick={resetFilterHandler}
                >
                  Clear all
                </button>
              </div>
              <div>
                <button
                  className="btn globalBtn filter_modal_btn"
                  onClick={closeFilters}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="select_column mob_hide">
        <Select
          value={selectedLocation}
          onChange={(selectedOption) =>
            onChangeOptionHandler(selectedOption, "location")
          }
          isSearchable={false}
          options={microlocationOptions}
          placeholder={"Location"}
          className="select_builder"
          menuPortalTarget={document.body}
        />
      </div>
      <div className="select_column mob_hide">
        <Select
          value={selectedBuilder}
          onChange={(selectedOption) =>
            onChangeOptionHandler(selectedOption, "builder")
          }
          isSearchable={false}
          options={builderOptions}
          placeholder={"Builder"}
          className="select_builder"
          menuPortalTarget={document.body}
        />
      </div>
      <div className="select_column mob_hide">
        <Select
          value={selectedStatus}
          onChange={(selectedOption) =>
            onChangeOptionHandler(selectedOption, "status")
          }
          isSearchable={false}
          options={statusOptions}
          placeholder={"Status"}
          className="select_builder"
          menuPortalTarget={document.body}
        />
      </div>
      <div className="select_column mob_hide">
        <Select
          value={selectedPrice}
          onChange={(selectedOption) =>
            onChangeOptionHandler(selectedOption, "price")
          }
          options={priceOptions}
          placeholder={"Price"}
          className="select_builder"
          menuPortalTarget={document.body}
        />
      </div>
      <div className="select_column mob_hide">
        <button
          className="clear_filter_btn"
          role="button"
          onClick={resetFilterHandler}
        >
          Clear All
        </button>
      </div>
    </div>
  );
};

export default Filter;
