import React, { useEffect, useState } from "react";
import prevImg from "../media/prev-arrow.svg";
import nextImg from "../media/next-arrow.svg";

const ScrollBtn = ({ cardRef, scrollContainerRef, isApartment, isCity }) => {
  const [cardWidth, setCardWidth] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [duplicateCards, setDuplicateCards] = useState(0);

  useEffect(() => {
    const updateCardWidth = () => {
      if (cardRef.current) {
        const width = cardRef.current.getBoundingClientRect().width;
        setCardWidth(width);
      }
    };

    updateCardWidth();

    const handleResize = () => {
      updateCardWidth();
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const newScrollPosition = container.scrollLeft - cardWidth;
      container.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
      setScrollPosition(newScrollPosition);
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const newScrollPosition = container.scrollLeft + cardWidth;
      container.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
      setScrollPosition(newScrollPosition);
    }
  };

  return (
    <div className="scroll_btn mob_hide">
      <img
        src={prevImg}
        className={
          !isCity
            ? "scroll_icon scroll_left"
            : "scroll_icon scroll_left scroll_icon2"
        }
        onClick={scrollLeft}
      />
      <img
        src={nextImg}
        className={
          !isCity
            ? "scroll_icon scroll_right"
            : "scroll_icon scroll_right scroll_icon2 scroll_icon3"
        }
        onClick={scrollRight}
        style={isApartment && { right: "-24px" }}
      />
    </div>
  );
};

export default ScrollBtn;
