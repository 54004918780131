import React from "react";
import ContactForm from "../form/ContactForm";
import { useParams } from "react-router-dom";

const FilterItemContactForm = () => {
  const { filteredValue } = useParams();
  const propertyType = [
    {
      id: 1,
      name: "villa",
      img: "https://propularity-bucket.s3.ap-south-1.amazonaws.com/image-1710220499731.jpg",
    },
    {
      id: 2,
      name: "plot",
      img: "https://propularity-bucket.s3.ap-south-1.amazonaws.com/image-1710220650414.jpg",
    },
    {
      id: 3,
      name: "office",
      img: "https://propularity-bucket.s3.ap-south-1.amazonaws.com/image-1710220247039.jpg",
    },
    {
      id: 4,
      name: "farmhouse",
      img: "https://propularity-bucket.s3.ap-south-1.amazonaws.com/image-1710220585870.png",
    },
    {
      id: 5,
      name: "penthouse",
      img: "https://propularity-bucket.s3.ap-south-1.amazonaws.com/image-1710220631504.jpg",
    },
    {
      id: 6,
      name: "apartment",
      img: "https://propularity-bucket.s3.ap-south-1.amazonaws.com/image-1710220727983.jpg",
    },
    {
      id: 7,
      name: "town-house",
      img: "https://propularity-bucket.s3.ap-south-1.amazonaws.com/image-1710220283930.jpg",
    },
    {
      id: 8,
      name: "studio-apartment",
      img: "https://propularity-bucket.s3.ap-south-1.amazonaws.com/image-1710220268860.jpg",
    },
    {
      id: 9,
      name: "sco",
      img: "https://propularity-bucket.s3.ap-south-1.amazonaws.com/image-1710220232881.jpeg",
    },
  ];

  return (
    <div className="container mb-md-5 mb-3">
      <div className="row">
        <div className="col-md-6 mob_hide">
          {propertyType
            ?.filter((img) => img.name === filteredValue)
            ?.map((item) => (
              <div className="property_type_img" key={item.id}>
                <img src={item.img} alt={item.name} className="img-fluid" />
              </div>
            ))}
        </div>
        <div className="col-md-6">
          <div className="contactForm">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterItemContactForm;
