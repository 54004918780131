import React from "react";
import buildingIcon from "../media/building-icon.png";

const Price = ({ name, city, plans }) => {
  return (
    <div className="mob_hide price_div">
      <div className="row">
        <h3 className="mt30">
          <span className="primary_color">
            {name}{" "}
            {name &&
              !name.endsWith("gurgaon") &&
              !name.endsWith("Gurgaon") &&
              !name.endsWith("Gurugram") &&
              !name.endsWith("gurugram") &&
              city}{" "}
          </span>
          Price
        </h3>
        <div className="project_configuration mt30">
          {plans?.map((plan, i) =>
            plan?.floor_plans?.map((item, j) => (
              <div className="configuration_box mb30" key={j}>
                <div className="config_size">
                  <h6>
                    {item?.name?.toLowerCase()?.includes("bhk")
                      ? item?.name + " Apartment"
                      : item?.name}
                  </h6>
                  <p>
                    {item?.area + (plan?.size_sq ? plan?.size_sq : "Sq.Ft")}
                  </p>
                </div>
                <div className="config_price">
                  <div>
                    <p>Price</p>
                    <p>
                      {item?.sale_price?.toString()?.toLowerCase() ===
                      "call for price" ? (
                        <a
                          href="tel:9999063322"
                          className="project_details_link"
                        >
                          Call For Offer
                        </a>
                      ) : (
                        <>
                          <span className="primary_color font-semibold">
                            ₹ {item?.sale_price}*
                          </span>
                          {/* <span> Onwards</span> */}
                        </>
                      )}
                    </p>
                  </div>
                  <img src={buildingIcon} alt="building" />
                </div>
              </div>
            ))
          )}
        </div>
        <p className="price_line">*Prices mentioned are starting price and may vary as per location, availability</p>
      </div>
    </div>
  );
};

export default Price;
