import { Route, Routes } from "react-router-dom";
import "./App.css";
import "./components/city/City.css";
import "./components/builder/Builder.css";
import "./components/homepage/top-localities/TopLocalities.css";
import "./components/homepage/builders-slider/BuildersSlider.css";
import "./components/microlocation-page/MicrolocationPage.css";
import "./components/project/Project.css";
import "./components/carousel/MyCarousel.css";
import "./components/card/Card.css";
import "./components/homepage/top-properties/TopProperties.css";
import "./components/request-call-button/RequestCallBtn.css";
import "./components/modal-form/ContactFormModal.css";
import "./components/filtered-items/FilteredItems.css";
import ErrorBoundary from "./components/error-boundry/ErrorBoundries";
import ScrollToTop from "./components/scrollTop/ScrollToTop";
import FeaturedCollectionProjects from "./components/city/FeaturedCollectionProjects";
import Navbar from "./components/navbar/Navbar";
import Builder from "./components/builder/Builder";
import Project from "./components/project/Project";
import Home from "./components/homepage/home/Home";
import FilteredItems from "./components/filtered-items/FilteredItems";
import Footer from "./components/footer/Footer";
import City from "./components/city/City";
import MicrolocationPage from "./components/microlocation-page/MicrolocationPage";
import ProjectImageGallery from "./components/project/ProjectImageGallery";
import Contact from "./components/contact/Contact";
import BuilderImageGallery from "./components/builder/BuilderImageGallery";
import ProjectTypePage from "./components/project-type-page/ProjectTypePage";
import PageNotFound from "./components/page-not-found/PageNotFound";
import FilteredItemByMicrolocation from "./components/filtered-item-by-microlocation/FilteredItemByMicrolocation";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={[<Navbar key={1} />, <Home key={2} />, <Footer key={3} />]}
        />
        <Route
          path="builder/:builder"
          element={[
            <Navbar key={4} />,
            <ErrorBoundary key={26}>
              <Builder key={5} />
            </ErrorBoundary>,
            <Footer key={6} />,
          ]}
        />
        <Route
          path="/:builder/:city/:slug"
          element={[
            <Navbar key={7} />,
            <ErrorBoundary key={8}>
              <Project />
            </ErrorBoundary>,
            <Footer key={9} />,
          ]}
        />
        <Route
          path="/:city/:microlocation"
          element={[
            <Navbar key={13} />,
            <ErrorBoundary key={14}>
              <MicrolocationPage />
            </ErrorBoundary>,
            <Footer key={15} />,
          ]}
        />
        <Route
          path="/:city/properties/:filteredValue"
          element={[
            <Navbar key={10} />,
            <ErrorBoundary key={11}>
              <FilteredItems />
            </ErrorBoundary>,
            <Footer key={12} />,
          ]}
        />
        <Route
          path="/:city/projects/:featured"
          element={[
            <Navbar key={26} />,
            <ErrorBoundary key={27}>
              <FeaturedCollectionProjects />
            </ErrorBoundary>,
            <Footer key={28} />,
          ]}
        />
        <Route
          path="/:city"
          element={[
            <Navbar key={16} />,
            <ErrorBoundary key={17}>
              <City />
            </ErrorBoundary>,
            <Footer key={18} />,
          ]}
        />
        <Route
          path="/:builder/:city/:slug/image-gallery"
          element={
            <ErrorBoundary key={19}>
              <ProjectImageGallery />
            </ErrorBoundary>
          }
        />
        <Route
          path="/contact"
          element={[
            <Navbar key={20} />,
            <ErrorBoundary key={21}>
              <Contact />
            </ErrorBoundary>,
            <Footer key={22} />,
          ]}
        />
        <Route
          path="/builder/:builder/image-gallery"
          element={
            <ErrorBoundary key={23}>
              <BuilderImageGallery />
            </ErrorBoundary>
          }
        />
        <Route
          path="/builder/:builder/projects/:projectType"
          element={[
            <Navbar key={23} />,
            <ErrorBoundary key={24}>
              <ProjectTypePage />
            </ErrorBoundary>,
            <Footer key={25} />,
          ]}
        />
        <Route path="/*" element={<PageNotFound key={26} />} />
        <Route
          path="/gurugram/properties/:filteredValue/:micro"
          element={[
            <Navbar key={27} />,
            <FilteredItemByMicrolocation key={28} />,
            <Footer key={29} />,
          ]}
        />
      </Routes>
    </>
  );
}

export default App;
