import React from "react";
import { RxCross2 } from "react-icons/rx";
import "./ContactFormModal.css";
import ContactForm from "../form/ContactForm";
import contactImage from "../media/offers_img.png";
import contactBg from "../media/detail-contact-bg.png";

function ContactFormModal({ closeModal, button_name, downloadPdf }) {
  return (
    <div
      className="modal_form_main position-relative"
      style={{
        backgroundImage: `url(${contactBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="cross_icon">
        <button>
          <RxCross2 className="close_icon" onClick={closeModal} />
        </button>
      </div>

      <ContactForm button_name={button_name} downloadPdf={downloadPdf} />
      <div className="position-absolute form_footer_wrapper">
        <div className="contact_form_footer position-relative">
          <div className="img_box_form">
            <img src={contactImage} alt="contact image" className="img-fluid" />
          </div>
          <div className="text-center w-100">
            <a href="tel:9999063322" className="form_email">
              Call Now : 9999063322
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactFormModal;
