import React, { useState, useEffect } from "react";
import HomeCard from "../card/HomeCard";
import ProjectTypesNav from "../project-types-navbar/ProjectTypesNav";
import { useQuery } from "@apollo/client";
import { GET_PROJECTS_BY_PLAN_TYPE } from "../../service/DataByPlanTypeService";
import { useParams } from "react-router-dom";
import CommonHeader from "../common-header/CommonHeader";
import MicrolocationTab from "../microlocation-tab/MicrolocationTab";
import FilterItemContactForm from "../filtered-items/FilterItemContactForm";
import ProjectSkeleton from "../loader/ProjectSkeleton";

function FilteredItemByMicrolocation() {
  const { micro } = useParams();
  const currentUrl = window.location.href;
  const parsedUrl = new URL(currentUrl);
  const path = parsedUrl.pathname;
  const pathParts = path.split("/");
  const desiredPart = pathParts[1];
  const city = desiredPart;
  const params = useParams();
  const { filteredValue } = params;
  const planType = filteredValue.split("-").join(" ");

  const { loading, error, data } = useQuery(GET_PROJECTS_BY_PLAN_TYPE, {
    variables: { city: city, planType: planType },
  });

  const [projects, setProjects] = useState([]);
  const matchedMicro = micro?.split("-").join(" ");

  useEffect(() => {
    if (data) {
      setProjects(
        data.topProjectsByPlanAndCity?.filter((item) =>
          item.location?.micro_location?.some(
            (location) => location.name.toLowerCase() === matchedMicro
          )
        )
      );
    }
  }, [data, matchedMicro]);

  const receiveDataFromChild = (datas) => {
    setProjects(datas);
  };

  return (
    <>
      <CommonHeader />
      <div className="filtered_items_nav_main">
        <ProjectTypesNav
          city={desiredPart}
          showFilter={true}
          sendDataToParent={receiveDataFromChild}
          projectsData={data?.topProjectsByPlanAndCity}
          isMobile={true}
        />
      </div>

      <div className="container">
        <div className="filtered_items_main">
          <MicrolocationTab />
          <div className="row">
            {loading && <ProjectSkeleton cards={8} />}
            {projects?.length > 0
              ? projects?.map((project, i) => {
                  return (
                    <div className="col-sm-6 col-md-3" key={i}>
                      <HomeCard
                        builder={project?.builder[0]?.name}
                        city={project?.location?.city[0]?.name}
                        projectName={project?.name}
                        startingPrice={project?.starting_price}
                        microlocationName={
                          project?.location?.micro_location[0]?.name
                        }
                        slug={project?.slug}
                        images={project?.images}
                        ratings={project?.ratings}
                      />
                    </div>
                  );
                })
              : !loading && <FilterItemContactForm />}
          </div>
        </div>
      </div>
    </>
  );
}

export default FilteredItemByMicrolocation;
