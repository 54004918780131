import { useEffect, useState } from "react";
import { GET_ALL_BUILDERS } from "../service/ProjectDetailsservice";
import { useQuery } from "@apollo/client";

const useGetAllBuilders = () => {
  const [builders, setBuilders] = useState([]);
  const { loading, data } = useQuery(GET_ALL_BUILDERS);

  useEffect(() => {
    if (!loading && data) {
      setBuilders(data?.builders);
    }
  }, [data, loading]);
  return [builders, loading];
};

export default useGetAllBuilders;
