import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_PROJECTS_BY_PLAN_TYPE } from "../service/DataByPlanTypeService";

const useGetTopProjectsByPlanType = (city, planType) => {
  const [projects, setProjects] = useState([]);
  const { loading, data } = useQuery(GET_PROJECTS_BY_PLAN_TYPE, {
    variables: {
      city: city,
      planType: planType,
    },
  });

  useEffect(() => {
    if (!loading && data) {
      setProjects(data?.topProjectsByPlanAndCity);
    }
  }, [data, loading, city, planType]);
  return [projects, loading];
};

export default useGetTopProjectsByPlanType;
