import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_PROJECTS_BY_CITY } from "../service/ProjectsByCityService";

const useGetTopProjectsByCity = (city) => {
  const [projects, setProjects] = useState([]);
  const { loading, data } = useQuery(GET_PROJECTS_BY_CITY, {
    variables: {
      city: city,
    },
  });

  useEffect(() => {
    if (!loading && data) {
      setProjects(data?.projectsByCity);
    }
  }, [data, loading, city]);
  return [projects, loading];
};

export default useGetTopProjectsByCity;
