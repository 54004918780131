import React, { useRef } from "react";
import RequestCallBtn from "../request-call-button/RequestCallBtn";
import { NO_IMAGE } from "../../utils/constants";
import ScrollBtn from "../scroll-btn/ScrollBtn";

const ApartmentForSale = ({
  name,
  plans_type,
  micro_location,
  images,
  plans,
  city,
  for_rent,
}) => {
  const cardRef = useRef(null);
  const containerRef = useRef(null);
  const isRent = false;
  return (
    <>
      {for_rent && (
        <>
          <div className="container">
            <h3 className="mt30">
              {plans_type[0]?.name} for Rent in <br className="desk_hide" />
              <span className="primary_color">
                {name}{" "}
                {name &&
                  !name.endsWith("gurgaon") &&
                  !name.endsWith("Gurgaon") &&
                  !name.endsWith("Gurugram") &&
                  !name.endsWith("gurugram") &&
                  city}{" "}
              </span>
            </h3>
            <div className="position-relative">
              {plans.some((item) => item?.floor_plans.length > 4) && (
                <ScrollBtn
                  cardRef={cardRef}
                  scrollContainerRef={containerRef}
                  isApartment={true}
                />
              )}
              <div className="row carosal" ref={containerRef}>
                {plans?.map((item) => {
                  return item?.floor_plans.map((plan, i) => (
                    <div
                      className="col-md-3 col-10"
                      key={plan._id}
                      ref={cardRef}
                    >
                      <div className="property_card">
                        <div className="img_card">
                          <img
                            src={
                              images.length !== 0
                                ? images[i % images.length]?.image?.s3_link
                                : NO_IMAGE
                            }
                            alt={images[i % images.length]?.name}
                            className="img-fluid img_cover"
                          />
                        </div>
                        <div className="card_body">
                          <h5 className="card_title">
                            {plan?.name?.toLowerCase()?.includes("bhk")
                              ? plan?.name +
                                (isRent
                                  ? " Apartment For Rent"
                                  : " Apartment For Sale") +
                                " " +
                                plan?.area +
                                (item?.size_sq ? item?.size_sq : "Sq.Ft") +
                                " in " +
                                name
                              : plan?.name +
                                " " +
                                plan?.area +
                                (item?.size_sq ? item?.size_sq : "Sq.Ft") +
                                " in " +
                                name}
                          </h5>
                          <p className="card_p">
                            {micro_location + ", " + city}
                          </p>
                          <div className="card_footer">
                            <div>
                              <p className="card_p mb-0">
                                <span>
                                  {(isRent
                                    ? "call for price"
                                    : plan?.sale_price?.toString().toLowerCase()
                                  ).toLowerCase() === "call for price" ? (
                                    <a href="tel:9999063322">Call For Price</a>
                                  ) : (
                                    "Starting ₹ " + plan?.sale_price
                                  )}
                                </span>
                              </p>
                            </div>
                            <RequestCallBtn button_name={"Enquire"} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ));
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ApartmentForSale;
