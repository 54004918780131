import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TopLocalities from "./TopLocalities";
import BuildersSlider from "./BuilderSlider";
import { useQuery } from "@apollo/client";
import { GET_ALL_BUILDERS } from "../../service/ProjectDetailsservice";
import { GET_MICROLOCATIONS } from "../../service/MicrolocationService";
import Select from "react-select";
import TopFooter from "../footer/TopFooter";
import CommonHeader from "../common-header/CommonHeader";
import PageNotFound from "../page-not-found/PageNotFound";
import NewLaunchProjects from "./NewLaunchProjects";
import ProjectAds from "./ProjectAds";
import FeaturedCollection from "./FeaturedCollection";
import FeaturedSco from "./FeaturedSco";
import krisumiAd from "../media/krisumi_add.jpg";
import ebdAdd from "../media/city-add2.jpg";
import ReadyToMoveProjects from "./ReadyToMoveProjects";
import aiplAdd from "../media/aipl-ads.jpg";
import TopCommercialProjects from "./TopCommercialProjects";
import gurgaon_bg from "../media/gurugram-ss.jpg";
import mumbai_bg from "../media/mumbai-ss.webp";

function City() {
  const { city } = useParams();
  const cityName = city.charAt(0).toUpperCase() + city.slice(1);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const navigate = useNavigate();

  const {
    loading: builderLoading,
    error: builderError,
    data: builderData,
  } = useQuery(GET_ALL_BUILDERS);

  const {
    loading: locationLoading,
    error: locationError,
    data: locationData,
  } = useQuery(GET_MICROLOCATIONS, {
    variables: { city: city },
  });

  const locationOptions = locationData?.microlocations?.map((location) => ({
    value: location._id,
    label: location.name,
  }));

  const onChangeOptionHandler = (selectedOption, dropdownIdentifier) => {
    switch (dropdownIdentifier) {
      case "location":
        setSelectedLocation(selectedOption);
        navigate(
          `/${city?.toLowerCase()}/${selectedOption?.label
            .split(" ")
            .join("-")
            .toLowerCase()}`
        );
        break;
      default:
        break;
    }
  };
  const validCities = ["gurugram", "mumbai"];

  if (!validCities.includes(city)) {
    return <PageNotFound />;
  }

  return (
    <>
      <CommonHeader />
      <div>
        <div className="city_video_box desk_hide">
          <div className="video_overlay"></div>
          {city === "gurugram" ? (
            <div className="city_bg_height">
              <img src={gurgaon_bg} alt="gurgaon" />
            </div>
          ) : (
            <div className="city_bg_height">
              <img src={mumbai_bg} alt="mumbai" />
            </div>
          )}
          <h2 className="cityPage_heading desk_hide">
            <span>{cityName}</span>
            <br />
            Where Property Comes To Life
          </h2>
          <div className="d-flex justify-content-center city_select_form">
            <Select
              value={selectedLocation}
              onChange={(selectedOption) =>
                onChangeOptionHandler(selectedOption, "location")
              }
              isSearchable={false}
              options={locationOptions}
              placeholder={"Search Location"}
              className="search_location"
            />
          </div>
          {/* <ProjectTypesNav city={city} showFilter={false} isMobile={true} /> */}
        </div>
        <div className="city_video_box mob_hide">
          <div className="video_overlay mob_hide"></div>
          {city === "gurugram" ? (
            <div className="city_bg_height">
              <img src={gurgaon_bg} alt="gurgaon" />
            </div>
          ) : (
            <div className="city_bg_height">
              <img src={mumbai_bg} alt="mumbai" />
            </div>
          )}

          <h2 className="cityPage_heading mob_hide">
            <span>{cityName}</span>
            <br />
            Where Property Comes To Life
          </h2>
          <div className="d-flex justify-content-center city_select_form">
            <Select
              value={selectedLocation}
              onChange={(selectedOption) =>
                onChangeOptionHandler(selectedOption, "location")
              }
              isSearchable={false}
              options={locationOptions}
              placeholder={"Search Location"}
              className="search_location"
            />
          </div>
        </div>
      </div>
      <NewLaunchProjects city={city} />
      {city === "gurugram" && (
        <ProjectAds
          link={"/krisumi/gurugram/krisumi-waterside-residence"}
          bg={krisumiAd}
          price={"3.60 Cr"}
          location={"Sector 36A, Gurgaon"}
          points={["Luxurious 2, 3BHK Apartments", "Under Construction"]}
          name={"Krisumi Waterside Residences"}
        />
      )}
      <TopLocalities cityName={cityName} myCity={city} />
      <FeaturedSco city={city} />
      {city === "gurugram" && (
        <ProjectAds
          link={`/emaar-india/gurugram/emaar-ebd-83`}
          bg={ebdAdd}
          price={"4.28 Cr"}
          location={"Sector 83, Gurgaon"}
          name={"Emaar Business District 83"}
          points={[
            "Shop Cum Office Plots",
            "A premium highway retail destination",
          ]}
          isBlack={true}
        />
      )}
      <ReadyToMoveProjects myCity={city} />
      <TopCommercialProjects city={city} />
      {city === "gurugram" && (
        <ProjectAds
          link={`/aipl/gurugram/aipl-sector88-retail-market`}
          bg={aiplAdd}
          price={"2.50 Cr"}
          location={"Sector 88, Gurgaon"}
          name={"AIPL Joy District"}
          points={["New Launch", "Retail Shops, Restaurant & Food Court"]}
        />
      )}
      <FeaturedCollection city={city} />
      <BuildersSlider
        builders={builderData?.builders?.slice(0, 20)}
        city={city}
      />
      <TopFooter />
    </>
  );
}

export default City;
