import React from "react";
import "./TopLocalities.css";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import goal from "../../media/goal.gif";

function TopLocalities() {
  return (
    <div className="mt100 localities_main">
      <div className="container">
        <div className="row">
          <h2 className="heading">
            Featured{" "}
            <span className="primary_color position-relative">
              Cities{" "}
              <img
                src={goal}
                alt="goal"
                className="featured_goal position-absolute"
              />
            </span>
          </h2>
          <p className="heading_text mob_hide">
            Find Your Home In The City Of Your Choice
          </p>
          <div className="col-6 col-md-3 mt30">
            <Link to="/gurugram">
              <div className="localities_card">
                <div className="img">
                  <LazyLoadImage
                    src="https://dwarkaexpressway-bucket.s3.ap-south-1.amazonaws.com/images/61f8acacbc0b5a70ae0125658f503b.jpg"
                    alt="Gurugram City"
                    className="img-fluid"
                  />
                </div>
                <div className="localities_card_overlay h40 localities_card_overlay1">
                  <div className="overlay_text">
                    <h3>Gurugram</h3>
                    <p>1,200+ Properties</p>
                  </div>
                </div>
                <div className="overlay2"></div>
              </div>
            </Link>
          </div>
          <div className="col-6 col-md-3 mt30">
            <Link to="/mumbai">
              <div className="localities_card">
                <div className="img">
                  <LazyLoadImage
                    src="https://dwarkaexpressway-bucket.s3.ap-south-1.amazonaws.com/images/51a7026b77b3955607e10ee473cc4e.jpg"
                    alt="Mumbai City"
                    className="img-fluid"
                  />
                </div>
                <div className="localities_card_overlay h40 localities_card_overlay2">
                  <div className="overlay_text">
                    <h3>Mumbai</h3>
                    <p>1,000+ Properties</p>
                  </div>
                </div>
                <div className="overlay2"></div>
              </div>
            </Link>
          </div>
          <div className="col-6 col-md-3 mt30">
            <div className="localities_card">
              <div className="img">
                <LazyLoadImage
                  src="https://dwarkaexpressway-bucket.s3.ap-south-1.amazonaws.com/images/b02d341a9b169e8afa4b8d10472b83.jpg"
                  alt="Delhi City"
                  className="img-fluid"
                  style={{ filter: "grayScale(100%)" }}
                />
              </div>
              <div className="localities_card_overlay h40 localities_card_overlay3">
                <div className="overlay_text">
                  <h3>Delhi</h3>
                  <p>Coming Soon</p>
                </div>
              </div>
              <div className="overlay2"></div>
            </div>
          </div>
          <div className="col-6 col-md-3 mt30">
            <div className="localities_card">
              <div className="img">
                <LazyLoadImage
                  src="https://dwarkaexpressway-bucket.s3.ap-south-1.amazonaws.com/images/97ec85e4960b756b37b2f815d74d01.jpg"
                  alt="location name"
                  className="img-fluid"
                  style={{ filter: "grayScale(100%)" }}
                />
              </div>
              <div className="localities_card_overlay h40 localities_card_overlay4">
                <div className="overlay_text">
                  <h3>Noida</h3>
                  <p>Coming Soon</p>
                </div>
              </div>
              <div className="overlay2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopLocalities;
