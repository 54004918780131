import React from "react";
import useGetTopProjectsByStatus from "../../utils/useGetProjectsByStatus";
import HomeCard from "../card/HomeCard";
import star from "../media/heading_star.gif";
import ProjectSkeleton from "../loader/ProjectSkeleton";

const NewLaunchProjects = ({ city }) => {
  const [projects, loading] = useGetTopProjectsByStatus(city, "new launch");

  return (
    <div
      className={
        city === "gurugram"
          ? "container mt100 city_container_main p50"
          : "container mt100 city_container_main2"
      }
    >
      <h1 className="heading">
        New Launch Projects in{" "}
        <span className="primary_color text-capitalize">
          {city} <img src={star} alt="star" />
        </span>
      </h1>
      <div className="row city_row">
        <p className="heading_text mob_hide">
          Explore newly launched properties based on your preferences
        </p>
        {loading ? (
          <ProjectSkeleton cards={4} />
        ) : (
          projects?.slice(0, 8)?.map((element) => {
            return (
              <div className="col-10 col-md-3 mt30" key={element?._id}>
                <HomeCard
                  builder={element?.builder[0].name
                    .split(" ")
                    .join("-")
                    .toLowerCase()}
                  city={city}
                  projectName={element?.name}
                  startingPrice={element?.starting_price}
                  microlocationName={element?.location?.micro_location[0]?.name}
                  slug={element?.slug}
                  images={element?.images}
                  ratings={element?.ratings}
                />
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default NewLaunchProjects;
