import React from "react";
import "./FeaturedCollection.css";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import useGetTopProjectsByPlanType from "../../utils/useGetProjectsByPlanType";
import star from "../media/heading_star.gif";

function FeaturedSco({ city }) {
  const [projects, loading] = useGetTopProjectsByPlanType(city, "sco");

  return (
    <>
      {projects?.length > 0 && (
        <div className="featured_box" style={{ paddingBottom: "0" }}>
          <div className="container">
            <div className="featured_heading_box">
              <h2 className="heading featured_heading">
                SCO Plots in{" "}
                <span className="primary_color text-capitalize">
                  {city} <img src={star} alt="star" />
                </span>
              </h2>
              <p className="heading_text mob_hide">
                Explore Sco Plots in Gurgaon based on your preference
              </p>
            </div>
            <div className="row mt30">
              <div className="col-12 col-md-6 featured_left_box">
                <Link
                  to={`/${projects[0]?.builder[0]?.name
                    ?.toLowerCase()
                    ?.split(" ")
                    ?.join("-")}/${city}/${projects[0]?.slug}`}
                >
                  <div className="featured_new_launch">
                    <img
                      src={projects[0]?.images[0]?.image?.s3_link}
                      alt="new launch projects"
                      className="img-fluid"
                    />
                  </div>
                  <div className="d-flex justify-content-between w95 mt-md-3 mt-2 align-items-center">
                    <h3 className="featured_collection_h3">
                      {projects[0]?.name}
                    </h3>
                    <div className="featured_star">
                      <i
                        class="fa-solid fa-star primary_color"
                        aria-hidden="true"
                      ></i>
                      <span>{projects[0]?.ratings}</span>
                    </div>
                  </div>
                  <p className="mt-md-2 mt-0 mb-1">
                    {projects[0]?.location?.micro_location[0]?.name +
                      ", " +
                      projects[0]?.location?.city[0]?.name}
                  </p>
                  <div className="d-flex justify-content-between align-items-center w95">
                    <p className="mb-0">
                      Starting{" "}
                      <span
                        className="primary_color"
                        style={{ fontWeight: "600" }}
                      >
                        ₹{projects[0]?.starting_price}
                      </span>
                    </p>
                    <p className="featured_explore">
                      Explore <BsArrowRight />
                    </p>
                  </div>
                </Link>
              </div>

              <div className="col-12 col-md-6 featured_right">
                <div className="featured_card_box">
                  <div className="featured_card">
                    <div className="featured_ready_move">
                      <Link
                        to={`/${projects[1]?.builder[0]?.name
                          ?.toLowerCase()
                          ?.split(" ")
                          ?.join("-")}/${city}/${projects[1]?.slug}`}
                      >
                        <img
                          src={projects[1]?.images[0]?.image?.s3_link}
                          alt="ready to move"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="ms-4 featured_collection_status">
                      <div className="d-flex justify-content-between w95 mt-md-3 mt-0 align-items-center">
                        <Link
                          to={`/${projects[1]?.builder[0]?.name
                            ?.toLowerCase()
                            ?.split(" ")
                            ?.join("-")}/${city}/${projects[1]?.slug}`}
                        >
                          <h3 className="featured_collection_h3">
                            {projects[1]?.name}
                          </h3>
                          <div className="featured_star">
                            <i
                              class="fa-solid fa-star primary_color"
                              aria-hidden="true"
                            ></i>
                            <span>{projects[1]?.ratings}</span>
                          </div>
                        </Link>
                      </div>
                      <Link
                        to={`/${projects[1]?.builder[0]?.name
                          ?.toLowerCase()
                          ?.split(" ")
                          ?.join("-")}/${city}/${projects[1]?.slug}`}
                      >
                        <p className="featured_address">
                          {projects[1]?.location?.micro_location[0]?.name +
                            ", " +
                            projects[1]?.location?.city[0]?.name}
                        </p>
                        <div className="d-flex justify-content-between align-items-center w95">
                          <p className="mb-0">
                            Starting{" "}
                            <span
                              className="primary_color"
                              style={{ fontWeight: "600" }}
                            >
                              ₹{projects[1]?.starting_price}
                            </span>
                          </p>
                          <p className="featured_explore">
                            Explore <BsArrowRight />
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="featured_card ml2">
                    <div className="featured_ready_move">
                      <Link
                        to={`/${projects[2]?.builder[0]?.name
                          ?.toLowerCase()
                          ?.split(" ")
                          ?.join("-")}/${city}/${projects[2]?.slug}`}
                      >
                        <img
                          src={projects[2]?.images[0]?.image?.s3_link}
                          alt="under construction"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="ms-4 featured_collection_status">
                      <div className="d-flex justify-content-between w95 mt-md-3 mt-0 align-items-center">
                        <Link
                          to={`/${projects[2]?.builder[0]?.name
                            ?.toLowerCase()
                            ?.split(" ")
                            ?.join("-")}/${city}/${projects[2]?.slug}`}
                        >
                          <h3 className="featured_collection_h3">
                            {projects[2]?.name}
                          </h3>
                          <div className="featured_star">
                            <i
                              class="fa-solid fa-star primary_color"
                              aria-hidden="true"
                            ></i>
                            <span>{projects[2]?.ratings}</span>
                          </div>
                        </Link>
                      </div>
                      <Link
                        to={`/${projects[2]?.builder[0]?.name
                          ?.toLowerCase()
                          ?.split(" ")
                          ?.join("-")}/${city}/${projects[2]?.slug}`}
                      >
                        <p className="featured_address">
                          {projects[2]?.location?.micro_location[0]?.name +
                            ", " +
                            projects[2]?.location?.city[0]?.name}
                        </p>
                        <div className="d-flex justify-content-between align-items-center w95">
                          <p className="mb-0">
                            Starting{" "}
                            <span
                              className="primary_color"
                              style={{ fontWeight: "600" }}
                            >
                              ₹{projects[2]?.starting_price}
                            </span>
                          </p>
                          <p className="featured_explore">
                            Explore <BsArrowRight />
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FeaturedSco;
