import React, { useRef } from "react";
import "./City.css";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ScrollBtn from "../scroll-btn/ScrollBtn";
import useGetTopProjectsByStatus from "../../utils/useGetProjectsByStatus";
import goal from "../media/goal.gif";

function ReadyToMoveProjects({ myCity }) {
  const cardRef = useRef(null);
  const containerRef = useRef(null);
  const [projects, loading] = useGetTopProjectsByStatus(
    myCity,
    "ready to move"
  );

  return (
    <div className="mt100 localities_main">
      <div className="container">
        <h2 className="heading underline">
          Ready To Move Projects in{" "}
          <span className="primary_color text-capitalize">
            {myCity}{" "}
            <img
              src={goal}
              alt="goal"
              className="featured_goal position-absolute mob_hide"
            />
          </span>
        </h2>
        <div className="position-relative">
          {projects?.length > 4 && (
            <ScrollBtn
              cardRef={cardRef}
              scrollContainerRef={containerRef}
              isCity={true}
            />
          )}
          <div className="row top_localities" ref={containerRef}>
            {projects?.slice(0, 8)?.map((project) => {
              return (
                <div
                  className="col-8 col-md-3 mt30"
                  key={project?._id}
                  ref={cardRef}
                >
                  <Link
                    to={`/${project?.builder[0]?.name
                      .toLowerCase()
                      ?.split(" ")
                      ?.join("-")}/${myCity.toLowerCase()}/${project?.slug}`}
                  >
                    <div className="localities_card">
                      <div className="img">
                        <LazyLoadImage
                          src={project?.images[0]?.image?.s3_link}
                          alt="location name"
                          className="img-fluid"
                        />
                      </div>
                      <div className="localities_card_overlay city_localities">
                        <div className="overlay_text city_overlay_text mb-2 text-start">
                          <h3>{project?.name}</h3>
                          <p className="card_location">
                            {project?.location?.micro_location[0]?.name +
                              ", " +
                              project?.location?.city[0]?.name}
                          </p>
                          <p className="card_price">
                            Starting ₹ {project?.starting_price}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReadyToMoveProjects;
