import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_PROJECTS_BY_TYPE } from "../service/ProjectByTypeService";

const useGetTopProjectsByType = (city, type) => {
  const [projects, setProjects] = useState([]);
  const { loading, data } = useQuery(GET_PROJECTS_BY_TYPE, {
    variables: {
      city: city,
      type: type,
    },
  });

  useEffect(() => {
    if (!loading && data) {
      setProjects(data?.topProjectsByPlanAndCity);
    }
  }, [data, loading, city, type]);
  return [projects, loading];
};

export default useGetTopProjectsByType;
