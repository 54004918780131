import { useEffect, useState } from "react";
import { GET_PROJECT_DETAILS } from "../service/ProjectDetailsservice";
import { useQuery } from "@apollo/client";

const useGetProjectDetails = (slug) => {
  const [projectData, setProjectData] = useState([]);
  const { loading, data } = useQuery(GET_PROJECT_DETAILS, {
    variables: {
      slug: slug,
    },
  });

  useEffect(() => {
    if (!loading && data) {
      setProjectData(data?.projectDetails);
    }
  }, [data, loading, slug]);
  return [projectData, loading];
};

export default useGetProjectDetails;
