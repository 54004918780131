import React from "react";

const Amenities = ({ amenities, name, city }) => {
  return (
    <>
      {(amenities?.commercial?.length > 0 ||
        amenities?.residential?.length > 0) && (
        <>
          <div className="row">
            <h3 className="mt30">
              <span className="primary_color text_black_mob">
                {name + " " + city}
              </span>{" "}
              <br className="desk_hide" />
              Amenities
            </h3>
            {amenities?.commercial?.length > 0 &&
              amenities?.commercial?.map((amenity, i) => {
                return (
                  <div className="col-6 col-md-4 facility mt20" key={i}>
                    <img src={amenity?.icon} alt="amenity icon" />
                    <p>{amenity?.name}</p>
                  </div>
                );
              })}
            {amenities?.residential?.length > 0 &&
              amenities?.residential?.map((amenity, i) => {
                return (
                  <div className="col-6 col-md-4 facility mt20" key={i}>
                    <img src={amenity?.icon} alt="amenity icon" />
                    <p>{amenity?.name}</p>
                  </div>
                );
              })}
          </div>
          <hr className="divider_line" />
        </>
      )}
    </>
  );
};

export default Amenities;
