import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_BUILDERS_DATA } from "../service/BuildersService";

const useGetBuildersData = (slug) => {
  const [builderData, setBuilderData] = useState([]);
  const { loading, data } = useQuery(GET_ALL_BUILDERS_DATA, {
    variables: {
      slug: slug,
    },
  });

  useEffect(() => {
    if (!loading && data) {
      setBuilderData(data?.buildersBySlug);
    }
  }, [data, loading, slug]);
  return [builderData, loading];
};

export default useGetBuildersData;
