import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { NO_IMAGE } from "../../utils/constants";

const ImageSection = ({
  images,
  project_status,
  project_size,
  project_type,
  configuration,
  isBuilder,
  name,
  address,
  starting_price,
  ratings,
}) => {
  return (
    <>
      <div className="img_gal_mob mt30">
        <Carousel interval={null} controls={true} className="carousel-container" >
          {images?.map((image, i) => {
            return (
              <Carousel.Item key={i}>
                {images?.length > 0 && (
                  <div className="img_carosal">
                    <img
                      src={!isBuilder ? image?.image?.s3_link : image?.image}
                      alt={image?.alt}
                      className="img-fluid img_cover"
                    />
                  </div>
                )}
              </Carousel.Item>
            );
          })}
        </Carousel>
        {images?.length === 0 && (
          <div className="img_carosal">
            <img
              src={NO_IMAGE}
              alt="no image"
              className="img-fluid img_cover"
            />
          </div>
        )}
      </div>
      {/* <div className="col-12 col-md-3 pe-md-0 builder_overview mt-3 mt-md-0 mob_hide">
        <div className="d-flex">
          <img
            src="https://propularity-bucket.s3.ap-south-1.amazonaws.com/image-1705661882497.png"
            alt="icon"
            className="project_icons"
          />
          <div className="ms-3">
            <h4 className="detail_h4">
              {isBuilder ? "Cities" : "Project Type"}
            </h4>
            <p className="detail_p">{project_type}</p>
          </div>
        </div>
        <div className="d-flex mt-md-3">
          <img
            src="https://propularity-bucket.s3.ap-south-1.amazonaws.com/image-1705661934768.png"
            alt="icon"
            className="project_icons"
          />
          <div className="ms-3">
            <h4 className="detail_h4">
              {isBuilder ? "Configuration" : "Project Size"}
            </h4>
            <p className="detail_p">{project_size}</p>
          </div>
        </div>
        <div className="d-flex">
          <img
            src="https://propularity-bucket.s3.ap-south-1.amazonaws.com/image-1705662043715.png"
            alt="icon"
            className="project_icons"
          />
          <div className="ms-3">
            <h4 className="detail_h4">
              {isBuilder ? "Residential Projects" : "Configuration"}
            </h4>
            <p className="detail_p">{configuration}</p>
          </div>
        </div>
        <div className="d-flex mt-md-3">
          <img
            src="https://propularity-bucket.s3.ap-south-1.amazonaws.com/image-1705662080015.png"
            alt="icon"
            className="project_icons"
          />
          <div className="ms-3">
            <h4 className="detail_h4">
              {isBuilder ? "Commercial Projects" : "Project Status"}
            </h4>
            <p className="detail_p">{project_status}</p>
          </div>
        </div>
      </div> */}
      <div className="col-12 m60 desk_hide">
        <div className="property_head">
          <h1 className="builder_h1">{name}</h1>
          {ratings && <i className="fa-solid fa-star"></i>}
          <p className="detail_p d-inline-block">
            {ratings && <span className="me-2">{ratings}</span>} {address}
          </p>
        </div>
      </div>
    </>
  );
};

export default ImageSection;
