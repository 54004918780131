import React, { useRef } from "react";
import "./BuildersSlider.css";
import "../../carousel/MyCarousel.css";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useGetAllBuilders from "../../../utils/useGetAllBuilders";
import ScrollBtn from "../../scroll-btn/ScrollBtn";
import star from "../../media/heading_star.gif";

function BuildersSlider({ myClass, name }) {
  const [builders, loading] = useGetAllBuilders();
  const cardRef = useRef(null);
  const containerRef = useRef(null);

  return (
    <div
      className={
        myClass === "home_top_builders"
          ? "container builder_container mt100"
          : "other_builders_top"
      }
    >
      {myClass === "home_top_builders" ? (
        <>
          <h2 className="heading">
            Top Developers In{" "}
            <span className="primary_color">
              India <img src={star} alt="star" />
            </span>
          </h2>
          <p className="heading_text mob_hide">
            Discover Top Developers to Your Specific Preferences.
          </p>
        </>
      ) : (
        ""
      )}

      <div className="mt30">
        <div className="position-relative">
          <ScrollBtn cardRef={cardRef} scrollContainerRef={containerRef} />
          <div className="carousel-wrapper row" ref={containerRef}>
            {builders
              ?.filter((build) => {
                return build?.name !== name;
              })
              ?.map((builder, i) => {
                return (
                  <div
                    className="col-md-2 col-sm-3 col-5"
                    key={i}
                    ref={cardRef}
                  >
                    <Link to={`/builder/${builder?.slug}`}>
                      <div className="card builder_card">
                        <div className="builder_img">
                          <LazyLoadImage
                            src={builder?.BuilderLogo}
                            className="card-img-top"
                            alt={builder?.alt}
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuildersSlider;
