import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./Card.css";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Card({ project, isbuilderPage }) {
  const noImage =
    "https://propularity-bucket.s3.ap-south-1.amazonaws.com/image-1706006993246.png";
  return (
    <div
      className={isbuilderPage ? "property_homecard w96" : "property_homecard"}
    >
      <Carousel interval={null} controls={false}>
        {project?.images?.map((image, i) => {
          return (
            <Carousel.Item key={i}>
              <div className="img_card">
                <Link
                  to={`/${project?.builder[0]?.name
                    ?.split(" ")
                    ?.join("-")
                    ?.toLowerCase()}/${project?.location?.city[0]?.name?.toLowerCase()}/${
                    project?.slug
                  }`}
                  target="_blank"
                >
                  <LazyLoadImage
                    src={project?.images?.length !== 0 ? image?.image?.s3_link : noImage}
                    alt={image?.alt}
                    className="img-fluid img_cover"
                    visibleByDefault="https://propularity-bucket.s3.ap-south-1.amazonaws.com/image-1709960839725.png"
                  />
                </Link>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
      <Link
        to={`/${project?.builder[0]?.name
          ?.split(" ")
          ?.join("-")
          ?.toLowerCase()}/${project?.location?.city[0]?.name?.toLowerCase()}/${
          project?.slug
        }`}
        target="_blank"
        className="card-link"
      >
        <div className="card_body">
          <div className="card_title_box d-flex justify-content-between align-items-center">
            <h5 className="card_title d-inline-block">{project?.name}</h5>
            {project?.ratings && (
              <div className="star_image d-inline-block">
                <p>
                  <i className="fa-solid fa-star"></i>
                  {project?.ratings}
                </p>
              </div>
            )}
          </div>
          <p className="card_p homecard_p">
            {project?.location?.micro_location[0]?.name},{" "}
            {project?.location?.city[0]?.name}
          </p>
          {project?.starting_price.toString().toLowerCase() ===
          "call for price" ? (
            <p className="card_p mb-0 homecard_p">
              <span>
                <a href="tel:9999063322">Call for price</a>
              </span>
            </p>
          ) : (
            <p className="card_p mb-0 homecard_p">
              Starting <span>₹ {project?.starting_price}</span>
            </p>
          )}
        </div>
      </Link>
    </div>
  );
}

export default Card;
