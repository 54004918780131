import React, { useState } from "react";
import ContactFormModal from "../modal-form/ContactFormModal";
import Modal from "react-modal";
import { Link } from "react-router-dom";

const ProjectAds = ({ bg, location, points, price, name, isBlack, link }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div
      className="container mt100 proAd"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        borderRadius: "20px",
        overflow: "hidden",
      }}
    >
      <div className="position-relative ad-wrapper">
        <Link to={link}>
          <div className="cityad">
            <h2 className={isBlack && "text-black"}>{name}</h2>
            <p className={isBlack ? "text-black location" : "location"}>
              {location}
            </p>
            <ul>
              {points?.map((point, i) => (
                <li className={isBlack && "text-black"} key={i}>
                  {point}
                </li>
              ))}
            </ul>
            <button className={isBlack && "btn_black"}>
              ₹ {price} Onwards
            </button>
          </div>
        </Link>
        <button
          className={isBlack ? "btn_black enq" : "enq"}
          onClick={openModal}
        >
          Enquire Now
        </button>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <ContactFormModal closeModal={closeModal} />
        </Modal>
      </div>
    </div>
  );
};

export default ProjectAds;
