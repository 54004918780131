import React from "react";
import { NO_IMAGE } from "../../utils/constants";

const MasterPlan = ({ master_plan, name, city }) => {
  return (
    <>
      {master_plan?.s3_link && (
        <div className="row">
          <hr className="divider_line" />
          <h3 className="mt30">
            <span className="primary_color text_black_mob">
              {name}{" "}
              {name &&
                !name.endsWith("gurgaon") &&
                !name.endsWith("Gurgaon") &&
                !name.endsWith("Gurugram") &&
                !name.endsWith("gurugram") &&
                city}{" "}
            </span>{" "}
            <br className="desk_hide" /> Master Plan
          </h3>
          <div className="master_plan mt20">
            <img
              src={master_plan?.s3_link}
              data-mdb-img={master_plan?.s3_link}
              alt={name + " " + "master plan"}
              className="img-fluid"
            />
            <div className="view_floor_plan_img">
              <button
                type="button"
                className="btn view_master_btn"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal2"
              >
                View Master Plan
              </button>
            </div>
            <div
              className="modal fade"
              id="exampleModal2"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      {`${name + " " + city} master plan`}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <img
                      src={
                        master_plan?.s3_link ? master_plan?.s3_link : NO_IMAGE
                      }
                      alt={`${name} master plan`}
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MasterPlan;
